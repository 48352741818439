import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { map, Observable } from 'rxjs';
import { MBMDoor, MBMProject, MBMWorkorder, MBMWorkorderTemplate, MBMWorkorderDoor } from 'mbc-models';

@Injectable({
  providedIn: 'root'
})
export class MBMProjectService {

  constructor(private httpService: HttpService) { }

  public getProjectByEngineeringId(mbeProjectId:string): Observable<MBMProject>{
    return this.httpService.getObject<MBMProject>(`mbmProjects/mbe/${mbeProjectId}`);
  }

  public getDoorsByProjectId(mbmProjectId: string): Observable<MBMDoor[]> {
    return this.httpService.getObjects<MBMDoor>(`mbmProjects/${mbmProjectId}/doors`).pipe(
      map(doors => doors.sort((a, b) => a.doorNo.localeCompare(b.doorNo)))
    );
  }

  public getWorkorderDoorsByWorkorderId(mbmProjectId: string, workorderId): Observable<MBMWorkorderDoor[]> {
    return this.httpService.getObjects<MBMWorkorderDoor>(`mbmProjects/${mbmProjectId}/workorderDoors/${workorderId}`);
  }

  public getDefaultWorkorder(mbmProjectId: string): Observable<MBMWorkorder> {
    return this.httpService.getObject<MBMWorkorder>(`mbmProjects/${mbmProjectId}/workorder/default`);
  }

  public getDefaultWorkorderByTemplateId(mbmProjectId:string, templateId: string): Observable<MBMWorkorder>{
    return this.httpService.getObject<MBMWorkorder>(`mbmProjects/${mbmProjectId}/templates/${templateId}/workorder/default`);
  }

  public getWorkorderById(mbmProjectId:string, workorderId: string): Observable<MBMWorkorder>{
    return this.httpService.getObject<MBMWorkorder>(`mbmProjects/${mbmProjectId}/workorders/${workorderId}`);
  }

  public getWorkordersByTemplateId(mbmProjectId:string, templateId: string): Observable<MBMWorkorder[]>{
    return this.httpService.getObject<MBMWorkorder[]>(`mbmProjects/${mbmProjectId}/templates/${templateId}/workorders`);
  }

  public getWorkordersWithDefaultByTemplateId(mbmProjectId:string, templateId: string): Observable<MBMWorkorder[]>{
    return this.httpService.getObject<MBMWorkorder[]>(`mbmProjects/${mbmProjectId}/templates/${templateId}/workorders/withDefault`);
  }

  public getWorkorderTemplates(mbmProjectId:string): Observable<MBMWorkorderTemplate[]>{
    return this.httpService.getObject<MBMWorkorderTemplate[]>(`mbmProjects/${mbmProjectId}/templates`);
  }
}
