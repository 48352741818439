import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FloorPlanFile } from 'mbc-models';

@Component({
  selector: 'floor-menu-bar',
  templateUrl: './floor-menu-bar.component.html',
  styleUrls: ['./floor-menu-bar.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule]
})
export class FloorMenuBarComponent  implements OnInit {

  @Input() floorPlanFiles: FloorPlanFile[];
  @Output() floorSelected: EventEmitter<FloorPlanFile> = new EventEmitter();
  @ViewChild('menuItemContainer') itemContainer: ElementRef;

  private _selectedFloor: FloorPlanFile;

  @Input() set selectedFloor(value: FloorPlanFile){
    this._selectedFloor = value;
    if(this._selectedFloor){
      this.scrollIntoView(this._selectedFloor.id);
    }
  }
  get selectedFloor(): FloorPlanFile{
    return this._selectedFloor;
  }
 
  showLeftArrow: boolean = false;
  showRightArrow: boolean = false;

  constructor() { }

  ngOnInit() { }

  onSelectMenuItem(selected: FloorPlanFile){
    this.scrollIntoView(selected.id);
    this.floorSelected.emit(selected);
  }

  scrollIntoView(id: string) {
    let elementWithId = document.getElementById(id) as HTMLDivElement;
    if(elementWithId){
      setTimeout(() => {
        elementWithId.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      }, 50);
    }
  }

  onScrollToRight(){
    let scrollAmount = (this.itemContainer.nativeElement.offsetWidth/2);
    let newScroll = this.itemContainer.nativeElement.scrollLeft + scrollAmount;  
    this.itemContainer.nativeElement.scrollTo(
      {left: (newScroll), behavior: 'smooth' }); 
  }

  onScrollToLeft(){
    let scrollAmount = (this.itemContainer.nativeElement.offsetWidth/2);
    let newScroll = this.itemContainer.nativeElement.scrollLeft - scrollAmount;
    this.itemContainer.nativeElement.scrollTo(
      {left: (newScroll), behavior: 'smooth' }); 
  }

  private getContainerRect(): DOMRect{
    let container = this.itemContainer.nativeElement as HTMLDivElement;
    return container.getBoundingClientRect(); 
  }

  onscroll(){
    let firstElement = this.itemContainer.nativeElement.firstElementChild as HTMLDivElement;
    let lastElement = this.itemContainer.nativeElement.lastElementChild as HTMLDivElement;
    if(firstElement && lastElement){
      let firstElementRect = firstElement.getBoundingClientRect();
      let lastElementRect = lastElement.getBoundingClientRect();
      let containerRect =  this.getContainerRect();
      this.showLeftArrow = firstElementRect.left < containerRect.left? true: false;
      this.showRightArrow = containerRect.right+5 < lastElementRect.right ? true: false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['floorPlanFiles']) {
      if (this.selectedFloor) {
        setTimeout(() => {
          this.onscroll();
          this.scrollIntoView(this.selectedFloor.id);
        }, (1500));
      }
    }
  }
}
