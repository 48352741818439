<div class="confirm-file-upload-card-wrapper">
  <div class="confirm-file-upload-card-content-holder">
    <div class="confirm-file-upload-card-header">    
      <img src="/assets/icon/uploaded.png" class="confirm-file-upload-card-icon" alt="Upload progress">
      <div class="confirm-file-upload-card-name">
        {{"confirmFileUploadCard.component.projectName" | translate}} - {{confirmFileUploading.file.name}} 
      </div>
      <div class="confirm-file-upload-card-old-name" *ngIf="confirmFileUploading.oldFile != undefined">
        {{(confirmFileUploading.oldFile != undefined) 
          ? ("confirmFileUploadCard.component.oldProjectName" | translate) + ' - ' + (confirmFileUploading.oldFile.pdfFileName) 
          : ''}}
      </div>
    </div>
  </div>
</div>