import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { MBCProject, MBEProjectUserRole } from 'mbc-models';

@Component({
  selector: 'mbc-project-card',
  templateUrl: './mbc-project-card.component.html',
  styleUrls: ['./mbc-project-card.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule,TranslateModule]
})
export class MbcProjectCardComponent implements OnInit {

  isOpen = false;
  @ViewChild('popover') popover;
  
  @Input() mbcProject: MBCProject;
  @Input() selected: boolean;

  @Output() nextClick: EventEmitter<Event> = new EventEmitter();
  @Output() deleteClick: EventEmitter<Event> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

   onNextClick(){
    this.nextClick.emit();
  }

  presentPopover(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }

  onDeleteMenuClick() {
    if(!this.isAdminUser()){
      return false;
    }
    this.deleteClick.emit();
    this.isOpen = false;
  }

  isAdminUser(){
    return this.mbcProject.userRole == MBEProjectUserRole.ADMIN;
  }
}
