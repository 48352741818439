import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { MBCFunctionality } from 'mbc-enums';

@Component({
  selector: 'management-viewer-menu',
  templateUrl: './management-viewer-menu.component.html',
  styleUrls: ['./management-viewer-menu.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule]
})
export class ManagementViewerMenuComponent  implements OnInit {

  @Input() currentMenu: MBCFunctionality = MBCFunctionality.NONE;

  @Output() openProperties: EventEmitter<ManagementViewerMenuEvent> = new EventEmitter<ManagementViewerMenuEvent>();
  
  menuTypes = MBCFunctionality;

  constructor() { }

  ngOnInit() {}

  onSelectProperties(){
    this.currentMenu = this.currentMenu === MBCFunctionality.DOOR_PROPS 
        ? MBCFunctionality.NONE 
        : MBCFunctionality.DOOR_PROPS;
     this.openProperties.emit({currentMenu: this.currentMenu});
  }
}

export class  ManagementViewerMenuEvent{
  currentMenu: MBCFunctionality;
}
