import { Injectable } from "@angular/core";
import { Observable, from } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class BlobUtil {
  
    readBlobAsDataURL(blob: Blob): Observable<string> {
        return from(new Promise<string>((resolve, reject) => {
          let reader = new FileReader();
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        }));
      }
}