import { Component, OnInit } from '@angular/core';
import { MainMenu } from '../MainMenu';
import { AuthenticationService } from 'mbc-services';
import { IonicModule, MenuController } from '@ionic/angular';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'mbp-viewer-main-menu',
  templateUrl: './mbp-viewer-main-menu.component.html',
  styleUrls: ['./mbp-viewer-main-menu.component.scss'],
  standalone: true,
  imports: [IonicModule, TranslateModule],
})
export class MBPViewerMainMenuComponent  extends MainMenu implements OnInit {

  constructor(authService: AuthenticationService, menuController: MenuController, router: Router) {
    super(authService, menuController, router)
  }

  ngOnInit() {}

}