import { Injectable } from '@angular/core';
import { AuthenticationService } from 'mbc-services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private authService: AuthenticationService) {}

  canActivate() {
    if (this.authService.isAuthenticated()) {
      return true;
    }
    this.authService.login();
    return false;
  }
}
