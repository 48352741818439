import { Component, OnInit } from '@angular/core';
import { IonicModule, MenuController } from '@ionic/angular';
import { TranslateModule} from '@ngx-translate/core';
import { MainMenu } from '../MainMenu';
import { AuthenticationService } from 'mbc-services';
import { Router } from '@angular/router';

@Component({
  selector: 'project-dashboard-main-menu',
  templateUrl: './project-dashboard-main-menu.component.html',
  styleUrls: ['./project-dashboard-main-menu.component.scss'],
  standalone: true,
  imports: [IonicModule, TranslateModule]
})
export class ProjectDashboardMainMenuComponent extends MainMenu  implements OnInit {

  constructor(authService: AuthenticationService, menuController: MenuController, router: Router) {
    super(authService, menuController, router)
  }

  ngOnInit() {}

}
