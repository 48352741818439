import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpContextToken, HttpResponse, HttpErrorResponse } 
  from '@angular/common/http';
import { Observable, of, catchError, from, throwError, switchMap, map } from 'rxjs';
import {
  AuthenticationService,
  DatacontextService,
  ErrorMessageService,
} from '../services';
import { TranslateService } from '@ngx-translate/core';

export const LOGIN_REQUEST = new HttpContextToken(() => false);

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private datacontext: DatacontextService,
    private authService: AuthenticationService,
    private errorMessageService: ErrorMessageService,
    private translate: TranslateService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.datacontext.log('[AuthInterceptor] - Intercepting request...');
    /**
     * ToDo : this is not necessary
     */
    if (req.context.get(LOGIN_REQUEST)) {
      // Amila - Set til om pages that dos not require authentication
      return next.handle(req);
    }
    if (this.authService.isAuthenticated()) {
      this.datacontext.log('[AuthInterceptor] - Setting request headers...');
      return from(this.authService.getUser()).pipe(
        switchMap((user) => {
          req = req.clone({
            setHeaders: { Authorization: `Bearer ${user?.access_token}` },
          });
          return next.handle(req).pipe(
            catchError((response) => {
              if (response instanceof HttpErrorResponse) {
                if (response.status == 403 || response.status == 401) {
                  this.authService.logout();
                }
                if(response.status === 461){
                  this.errorMessageService.displayRefreshErrorMessage(()=>this.refreshPage());
                  let error: any = new Error();
                  error.response = response;
                  error.handled = true;
                  return throwError(() => error);
                }
              }
              return throwError(() => response);
            })
          );
        })
      );
    }
    return next.handle(req);
  }

  private mockTokenResponse(): Observable<HttpEvent<any>> {
    return of(
      new HttpResponse({
        body: {
          token:
            'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiYWRtaW4iOnRydWUsImlhdCI6MTUxNjIzOTAyMn0.NHVaYe26MbtOYhSKkoKYdFVomg4i8ZJd8_-RU8VNbftc4TSMb4bXP3l3YlNWACwyXPGffz5aXHc6lty1Y2t4SWRqGteragsVdZufDn5BlnJl9pdR_kdVFUsra2rWKEofkZeIC4yWytE58sMIihvo9H1ScmmVwBcQP6XETqYd0aSHp1gOa9RdUPDvoXQ5oqygTqVtxaDr6wUFKrKItgBMzWIdNZ6y7O9E0DhEPTbE9rfBo6KTFsHAZnMg4k68CDp2woYIaXbmYTWcvbzIuHO7_37GT79XdIwkm95QJ7hYC9RiwrV7mesbY4PAahERJawntho0my942XheVLmGwLMBkQ',
        },
      })
    );
  }

  private refreshPage(){
    window.location.reload();
  }
}
