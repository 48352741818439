import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { MBEProject } from 'mbc-models';

@Component({
  selector: 'connect-project-card',
  templateUrl: './connect-project-card.component.html',
  styleUrls: ['./connect-project-card.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule],
})
export class ConnectProjectCardComponent  implements OnInit {

  @Input() project: MBEProject;
  @Input() selected: boolean;
  @Output() connectProjectClick: EventEmitter<MBEProject> = new EventEmitter();
  
  constructor() { }

  ngOnInit() {
  }

  onConnectProjectClick(project:MBEProject){
    this.connectProjectClick.emit(project);
  }
}
