import { Injectable, inject } from '@angular/core';
import { HttpService } from './http.service';
import { DoorDocument, Document } from 'mbc-models';
import { Observable, map, switchMap } from 'rxjs';
import { BlobUtil } from 'mbc-utils';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { AppType } from 'mbc-enums';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  private http: HttpService = inject(HttpService);
  private blobUtil: BlobUtil   = inject(BlobUtil);

  constructor() { }

  getDocumentsByProjectId(projectId: string){
    return this.http.getObject<Document[]>(`projects/${projectId}/documents`);
  }

  getDoorDocumentsByProjectId(projectId: string){
    return this.http.getObject<DoorDocument[]>(`projects/${projectId}/doorDocuments`);
  }

  public getDocumentsByDoor(projectId: string, doorId: string): Observable<Document[]>{
    return this.http.getObject<Document[]>(`projects/${projectId}/documents/byDoor/${doorId}`);
  }

  public getDocumentByFileName(projectId: string, fileName: string): Observable<Document>{
    return this.http.getObjectByPost<any>(`projects/${projectId}/documents/byFileName`, { fileName: fileName });
  }

  attachDocumentToDoor(projectId: string, documentId: string, doorId: string): Observable<DoorDocument>{
    return this.http.patchAndGetObjects(`projects/${projectId}/doors/${doorId}/documents/${documentId}/attach`,{});
  }

  detachDocumentFromDoor(projectId: string, documentId: string, doorId: string): Observable<DoorDocument>{
    return this.http.patchAndGetObjects<DoorDocument, any>(`projects/${projectId}/doors/${doorId}/documents/${documentId}/detach`,{});
  }

  attachDocumentToDoors(projectId: string, documentId: string, doorIds: string[]): Observable<DoorDocument[]>{
    return this.http.patchAndGetObjects<DoorDocument[], any>(`projects/${projectId}/documents/${documentId}/attach/bulk`, doorIds) 
  }

  detachDocumentFromDoors(projectId: string, documentId: string, doorIds: string[]): Observable<DoorDocument[]>{
    return this.http.patchAndGetObjects<DoorDocument[], any>(`projects/${projectId}/documents/${documentId}/detach/bulk`, doorIds) 
  }

  public deleteDocument(projectId: string, documentId: string): Observable<void>{
    return this.http.deleteObject<void>(`projects/${projectId}/documents/${documentId}`);
  }

  public uploadDocument(fileToUpload:File, projectId:string, appType: AppType) : Observable<DocumentFileUpload>{
    const formData = new FormData();
    formData.append('pdfFile', fileToUpload, fileToUpload.name);
    formData.append('appType', appType.toString());
    return this.http.uploadFile<any>(`projects/${projectId}/documents`,formData)
      .pipe(map(x => this.setEventCondition(x)));   
  }

  public uploadDocumentByDoor(
    fileToUpload:File, projectId:string, doorId: string, appType: AppType) : Observable<DocumentFileUpload>{

    const formData = new FormData();
    formData.append('pdfFile', fileToUpload, fileToUpload.name);
    formData.append('appType', appType.toString());
    return this.http.uploadFile<any>(`projects/${projectId}/documents/byDoor/${doorId}`,formData)
      .pipe(map(x => this.setEventCondition(x)));   
  }

  public updateDocumentByDoor(
    fileToUpload:File, projectId:string, documentId: string, doorId: string, appType: AppType) : Observable<DocumentFileUpload>{
    const formData = new FormData();
    formData.append('pdfFile', fileToUpload, fileToUpload.name);
    formData.append('appType', appType.toString());
    return this.http.uploadFile<any>(`projects/${projectId}/documents/${documentId}/byDoor/${doorId}`,formData)
      .pipe(map(x => this.setEventCondition(x)));   
  }

  public updateDocument(fileToUpload:File, projectId:string, documentId: string, appType: AppType) : Observable<DocumentFileUpload>{
    const formData = new FormData();
    formData.append('pdfFile', fileToUpload, fileToUpload.name);
    formData.append('appType', appType.toString());
    return this.http.uploadFile<any>(`projects/${projectId}/documents/${documentId}`,formData)
      .pipe(map(x => this.setEventCondition(x)));   
  }

  public downloadDocument(documentId: string, projectId: string): Observable<string> {
    return this.http.downloadFile(`projects/${projectId}/documents/${documentId}/download`)
      .pipe(switchMap(data => this.blobUtil.readBlobAsDataURL(data)));
  }

  private setEventCondition(x:HttpEvent<any>): DocumentFileUpload{
    let progress: DocumentFileUpload = {
      progress : 0,
      finished: false,
      createdFile: undefined,
    };
    if(x.type == HttpEventType.Sent){
      return progress;
    }
    if(x.type == HttpEventType.UploadProgress){    
      progress.progress = (x.loaded/ x.total)- 0.1;  
      return progress;
    }
    if(x.type == HttpEventType.Response){
      progress.progress = 1,
      progress.finished = true;
      if(x.ok){
        progress.createdFile = x.body;
      }
      return progress;
    }
  }
}

export class DocumentFileUpload{
  progress: number;
  finished: boolean;
  createdFile: Document;
}