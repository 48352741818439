import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-mbc-error-message',
  templateUrl: './mbc-error-message.component.html',
  styleUrls: ['./mbc-error-message.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule,TranslateModule],
})
export class MBCErrorMessageComponent  implements OnInit {

  header: string;
  message: string;
  button: MBCErrorHandlerButton;

  constructor(private modalController: ModalController) { }

  ngOnInit() {}

  onDismiss() {
    this.modalController.dismiss({isButtonClick: true});
  }

  onClick(button: any){    
    if(button.handler) button.handler(); 
    this.modalController.dismiss({isButtonClick: true});
  }
}

export class MBCErrorHandlerButton{
  text?: string;
  handler?: () => void;
}
