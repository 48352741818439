import { Injectable } from '@angular/core';
import { ErrorCode } from 'mbc-enums';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessageService } from 'mbc-services';
import { MBCError } from 'mbc-models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {

  private static VALIDATION_ERROR_STATUS = 460;

  constructor(private translate: TranslateService, private errorMessageService: ErrorMessageService) { }

  handleValidationError(error:MBCError){
    if(error.statusCode == ErrorHandlingService.VALIDATION_ERROR_STATUS){
      let errorMessage = this.getTranslatedValidationErrorMessage(error.validationErrorCode);
      this.errorMessageService.displayValidationErrorMessage(errorMessage);
      error.handled = true;
    }
    throw error;
  }

  handleReplaceFileValidationError(error:MBCError) : boolean{
    if(error.statusCode == ErrorHandlingService.VALIDATION_ERROR_STATUS){
      return (error.validationErrorCode == ErrorCode.MBC_REPLACE_PDF_WRONG_RATIO);
    }
    throw error;
  }

  private getTranslatedValidationErrorMessage(code: number): string{
    switch(code){
      case(ErrorCode.MBE_PROJECT_NOT_FOUND): 
        return this.translate.instant("errorMessages.validation.mbeProjectNotFound");
      case(ErrorCode.MBE_PROJECT_ALREADY_CONNECTED) : 
        return this.translate.instant("errorMessages.validation.mbeProjectAlreadyConnected");
      case(ErrorCode.MBP_PROJECT_NOT_FOUND): 
        return this.translate.instant("errorMessages.validation.mbpProjectNotFound");
      case(ErrorCode.MBP_PROJECT_INACTIVE): 
        return this.translate.instant("errorMessages.validation.mbpProjectInactive");
      case(ErrorCode.MBP_PROJECT_DOORS_NOT_SYNCED): 
        return this.translate.instant("errorMessages.validation.mbpDoorsNotSynced");
      case(ErrorCode.MBM_PROJECT_NOT_FOUND): 
        return this.translate.instant("errorMessages.validation.mbmProjectNotFound");
      case(ErrorCode.MBM_PROJECT_INACTIVE): 
        return this.translate.instant("errorMessages.validation.mbmProjectInactive");
      case(ErrorCode.MBM_PROJECT_NO_WORK_ORDER): 
        return this.translate.instant("errorMessages.validation.mbmProjectNoWorkorders");
      case(ErrorCode.USER_NOT_MBE_ADMIN): 
        return this.translate.instant("errorMessages.validation.userNotMBEAdmin");
      case(ErrorCode.USER_NOT_MBP_ADMIN): 
        return this.translate.instant("errorMessages.validation.userNotMBPAdmin");
      case(ErrorCode.USER_NOT_MBM_ADMIN): 
        return this.translate.instant("errorMessages.validation.userNotMBMAdmin");
      case(ErrorCode.MBC_PROJECT_NOT_FOUND): 
        return this.translate.instant("errorMessages.validation.mbcProjectNotFound");
      case(ErrorCode.USER_NOT_MBE_ADMIN_DELETE_PROJECT): 
        return this.translate.instant("errorMessages.validation.userNotMBEAdminDeleteProject");
      case(ErrorCode.MBC_DOORNODE_ALREADY_EXISTS): 
        return this.translate.instant("errorMessages.validation.mbcDoorNodeAlreadyExists");
      case(ErrorCode.MBC_DOORNODE_NOT_FOUND): 
        return this.translate.instant("errorMessages.validation.mbcDoorNodeNotFound");
        case(ErrorCode.CODE_NULL_OR_EMPTY): 
          return this.translate.instant("errorMessages.validation.codeNullOrEmpty");
      case(ErrorCode.MBC_DOCUMENT_ALREADY_ATTACHED): 
        return this.translate.instant("errorMessages.validation.documentAlreadyAttached");
      default: return this.translate.instant("errorMessages.validation.generalError");
    }
  }

  handleAccessError(error): Observable<any>{
    let header = this.translate.instant("errorMessages.access.header"); 
    let errorMessage = this.translate.instant("errorMessages.access.unknownError");  
    if(error.statusCode == ErrorHandlingService.VALIDATION_ERROR_STATUS) {
      errorMessage = this.getTranslatedValidationErrorMessage(error.validationErrorCode);
    }
    return this.handleAndShowErrorMessage(error, header, errorMessage);
  }

  handleError(error, message): Observable<any>{
    let header = this.translate.instant("errorMessages.header");
    return this.handleAndShowErrorMessage(error, header, message);
  }

  handleAndShowErrorMessage(error, header: string, errorMessage: string):  Observable<any>{  
    this.errorMessageService.displayErrorMessage(header, errorMessage);
    error.handled = true;
    throw error;
  }
}
