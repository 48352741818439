import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConnectProjectCardComponent } from 'mbc-components';
import { MBCError, MBEProject } from 'mbc-models';
import { ProjectService, NotificationService } from 'mbc-services';
import { ErrorHandlingService } from 'mbc-errorhandling';

@Component({
  selector: 'app-connect-project',
  templateUrl: './connect-project.component.html',
  styleUrls: ['./connect-project.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, ConnectProjectCardComponent,TranslateModule],
})
export class ConnectProjectComponent implements OnInit {

  notConnectedProjects: MBEProject[];
  filteredProjects: MBEProject[];
  selectedProject: MBEProject;
  showSpinner: boolean = true;
  connecting: boolean = false;
  
  constructor(private modelCtrl: ModalController,
    private projectService: ProjectService,
    private notificationService: NotificationService,
    public translate: TranslateService,
    private errorHandler: ErrorHandlingService) { }

  ngOnInit() {
    this.projectService.getNotConnectedProjects().subscribe(mbeProjects => {
      this.notConnectedProjects = mbeProjects;
      this.filteredProjects = mbeProjects;
      this.showSpinner = false;
    });
  }

  onCancel() {
    this.modelCtrl.dismiss();
  }

  onSelectProject(project: MBEProject) {
    this.selectedProject = project;
  }

  onConnect() {
    if(!this.connecting){
      this.connecting = true;
      this.projectService.connectProject(this.selectedProject.mbeProjectId).subscribe({
        next: createdProject => {
          this.notificationService.showSuccessNotification(this.translate.instant("connectProject.modal.notification.success"));
          this.modelCtrl.dismiss(createdProject);
          this.notConnectedProjects = this.notConnectedProjects.filter(project => {
            return project.mbeProjectId !== createdProject.mbeProjectId;
          });
        },
        error: (error: MBCError) => {
          this.connecting = false;
          this.errorHandler.handleValidationError(error);
        } 
      });
  
      this.selectedProject = null;
    }
    
  }

  handleInput($event) {
    const query = $event.detail.value?.toLowerCase() || '';
    const propertiesToSearch = [
      'name',
      'projectNo',
    ];

    this.filteredProjects = this.notConnectedProjects.filter((item) =>
      propertiesToSearch.some((prop) => {
        return item[prop]?.toString().toLowerCase().includes(query);
      })
    );
  }

  onConnectProject(mbeProject:MBEProject){
    this.selectedProject = mbeProject
    this.onConnect();
  }

}
