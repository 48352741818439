import { Injectable } from "@angular/core";

@Injectable()
export class DoubleTapUtil {

    isDoubleTap = false;

    doubleTap(): boolean {
        if (!this.isDoubleTap) {
            this.isDoubleTap = true;
            setTimeout(() => {
                this.isDoubleTap = false;
            }, 500);
        } else {
            return true;
        }
        return false;
    }
}