<div class="connect-project-card-wrapper" [class.mbc-card-selected]="selected" >
  <div class="connect-project-card-left-box"></div>
  <div class="connect-project-card-inner">
    <div class="connect-project-card-inner-row">
      <div class="connect-project-card-projectName">{{project.name}}</div>
      <div class="connect-project-card-projectNo">{{"connectProjectCard.component.ProjectNo" | translate}}: {{project.projectNo}}</div>
    </div>
    <div class="connect-project-card-inner-row">
      <img src="/assets/icon/time.png" class="connect-project-card-time-icon">
      <div class="connect-project-card-created-date">
        {{project.createdDate | date:'dd.MM.yyyy hh:mm'}}
      </div>
    </div>
  </div>
  <div class="connect-project-card-right">
    <ion-icon name="arrow-forward-circle-outline" (click)="onConnectProjectClick(project)" 
      class="connect-project-card-connect-button"></ion-icon>
  </div>
</div>
