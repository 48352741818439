import { MBPTradeTemplate } from "./mbp-trade-template.model";

export class MBPCompany{
    id:string;
    projectId:string;
    name:string;
    users:MBPCompanyUser[];
    tradeTemplate:MBPTradeTemplate;
}

export class MBPCompanyUser{
    mbeUserId:string;
    name:string;
    isCompanyLeader:boolean;
    email:string;
    mobile:string;
}