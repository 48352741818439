import { Injectable } from '@angular/core';
import { BASE_URL, environment } from 'src/environments/environment';
import { LoggedInUser, MBEProjectUserRole, MBMUserPermission, MBPUserPermission } from 'mbc-models';


@Injectable({
  providedIn: 'root'
})
export class DatacontextService {
  public baseUrl: string = BASE_URL;
  public Token: string;
  public changedDoorNodeCount = 0;
  public defaultNodeColor = "#A8A3A1";
  public loggedInUser  : LoggedInUser = new LoggedInUser();
  public currentProjectContext: ProjectContext;
  
  constructor() { }

  log(message:any){
    if (environment.log === true){
      console.log(message);
    }
  }

  logConfigInfo(){
    if (environment.log === true){
      console.log(environment.config);
    }
  }

}

export class ProjectContext{
  projectId: string;
  mbeProjectId: string;
  mbpProjectId: string;
  mbeUserRights: MBEProjectUserRole;
  mbpUserRights: MBPUserPermission;
  mbmUserRights: MBMUserPermission;
  projectName: string;
}