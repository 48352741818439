import { DoorNodeTemplateType } from "mbc-enums";

export class MBEProjectSetting{
    id: string;
    projectId:string;
    doorNodeTemplate: DoorNodeTemplateType;
    tagProperties : TagProperty[] = [];
    enableAnonymousUser : boolean;
}

export class TagProperty{
    field:string;
    header:string;
    orderNo:number;
}