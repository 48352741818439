import { Injectable, inject } from '@angular/core';
import { HttpService } from './http.service';
import { Observable, map } from 'rxjs';
import { MBEDoor } from 'mbc-models';

@Injectable({
  providedIn: 'root'
})
export class MbeDoorService {

  private httpService: HttpService = inject(HttpService);

  constructor() { }

  public getDoors(mbeProjectId:string) : Observable<MBEDoor[]>{
    return this.httpService.getObject<MBEDoor[]>(`mbeProjects/${mbeProjectId}/doors`).pipe(
      map(doors => doors.sort((a, b) => a.doorNo.localeCompare(b.doorNo)))
    );
  }

  public addNewDoors(mbeProjectId:string, doors: MBEDoor[]): Observable<MBEDoor[]>{
    return this.httpService.sendObjects<MBEDoor[]>(`mbeProjects/${mbeProjectId}/doors/bulk`, doors);
  }

  public deleteDoor(mbeProjectId: string, doorId: string): Observable<void>{
    return this.httpService.deleteObject<void>(`mbeProjects/${mbeProjectId}/doors/${doorId}`);
  }

  public updateDoor(mbeProjectId: string, doorId: string, doorValue: DoorValue): Observable<MBEDoor>{
    return this.httpService.patchObjects<any>(`mbeProjects/${mbeProjectId}/doors/${doorId}/values`, doorValue);
  }
}

export class DoorValue{
  fieldName: string;
  value: string;
}
