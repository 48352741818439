import { inject, Injectable } from "@angular/core";
import { HttpService } from "mbc-services";
import { map, Observable } from "rxjs";
import { DetailingCode } from "mbc-models";

@Injectable({
    providedIn: 'root'
})
export class ProjectDetailingService {

    private httpService: HttpService = inject(HttpService);

    constructor() { }
    
    addNewDetailingCode(projectId: string, code: DetailingCode): Observable<DetailingCode>{
        return this.httpService.sendObjects<DetailingCode>(`projects/${projectId}/detailingTemplate/codes`, code);
      }
    
    updateDetailingCode(projectId: string, code: DetailingCode): Observable<DetailingCode>{
        return this.httpService.patchObjects<DetailingCode>(`projects/${projectId}/detailingTemplate/codes/description`, code);
    }

    deleteDetailingCode(projectId: string, codes: DetailingCode[]): Observable<void> {
        return this.httpService.deleteObjects<any>(`projects/${projectId}/detailingTemplate/codes`, codes);
    }

    updateCodesColors(projectId: string, codes: DetailingCode[]): Observable<DetailingCode[]> {
        return this.httpService.patchObjects<DetailingCode[]>(`projects/${projectId}/detailingTemplate/codes/colors`, codes);
    }
}