import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { map, switchMap, Observable, from } from 'rxjs';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { FloorPlanFile } from 'mbc-models';
import { BlobUtil } from 'mbc-utils';

@Injectable({
  providedIn: 'root'
})
export class FloorplanService {

  constructor(private httpService: HttpService, private blobUtil: BlobUtil) { }

  public getFloorPlans(projectId:string) : Observable<FloorPlanFile[]>{
    return this.httpService.getObject<FloorPlanFile[]>(`projects/${projectId}/floorplans`).pipe(map(files => {
       files.forEach(file => {
        file.shortName = file.shortName ?? file.pdfFileName?.split('.')[0];
        file.progress = 1;
       });
       return files;
    }));
  }

  public uploadFile(fileToUpload:File,projectId:string) : Observable<FloorPlanFileUpload>{
    const formData = new FormData();
    formData.append('pdfFile', fileToUpload, fileToUpload.name);
    return this.httpService.uploadFile<any>(`projects/${projectId}/floorplans/fileUpload/`,formData)
      .pipe(map(x => {
        return this.setEventCondition(x);
      }));   
  }

  public replaceFile(fileToUpload:File,projectId:string, floorId?:string) : Observable<FloorPlanFileUpload>{
    const formData = new FormData();
    formData.append('pdfFile', fileToUpload, fileToUpload.name);
    return this.httpService.replaceFile<any>(`projects/${projectId}/floorplans/${floorId}`,formData)
      .pipe(map(x => {
        return this.setEventCondition(x);
      }));   
  }
  public replaceFileWithOriginalSize(fileToUpload:File,projectId:string, floorId?:string) : Observable<FloorPlanFileUpload>{
    const formData = new FormData();
    formData.append('pdfFile', fileToUpload, fileToUpload.name);
    return this.httpService.replaceFile<any>(`projects/${projectId}/floorplans/${floorId}/withOriginalSize`,formData)
      .pipe(map(x => {
        return this.setEventCondition(x);
      }));   
  }

  public getFloorPlanPngById(projectId: string, floorPlanId: string): Observable<string> {
    return this.httpService.downloadFile(`projects/${projectId}/floorplans/${floorPlanId}/png`)
      .pipe(
        switchMap(data => this.blobUtil.readBlobAsDataURL(data))
      );
  }

  public deleteFloorPlan(projectId:string, floorPlanId: string) : Observable<FloorPlanFile>{
    return this.httpService.deleteObject<FloorPlanFile>(
      `projects/${projectId}/floorplans/${floorPlanId}`);
  }

  private setEventCondition(x:HttpEvent<any>): FloorPlanFileUpload{
    let progress: FloorPlanFileUpload = {
      progress : 0,
      finished: false,
      createdFile: undefined,
    };
    if(x.type == HttpEventType.Sent){
      return progress;
    }
    if(x.type == HttpEventType.UploadProgress){    
      progress.progress = (x.loaded/ x.total)- 0.1;  
      return progress;
    }
    if(x.type == HttpEventType.Response){
      progress.progress = 1,
      progress.finished = true;
      if(x.ok){
        progress.createdFile = x.body;
      }
      return progress;
    }
  }

  public updateFileOrders(projectId: string, orderedFiles: FloorPlanOrder[]): Observable<FloorPlanOrder[]> {
    return this.httpService.patchObjects<FloorPlanOrder[]>(`projects/${projectId}/floorplans/order`, orderedFiles);
  }

  public UpdateShortName(projectId:string, updatingFloor: FloorPlanFile): Observable<FloorPlanFile> {
    return this.httpService.patchObjects<FloorPlanFile>(`projects/${projectId}/floorplans/${updatingFloor.id}/shortName`, updatingFloor);
  }
}

export class FloorPlanFileUpload{
  progress: number;
  finished: boolean;
  createdFile: FloorPlanFile;
}

export class FloorPlanOrder{
  id: string;
  order: number;
}