import { FloorPlanFile } from "mbc-models";

export class FloorPlanFileUploading {
    newFile: FileUploading[] = [];
    replaceFile: FileUploading[] = [];
  }

export class FileUploading{
    file: File;
    oldFile?: FloorPlanFile;
    order: number;
}