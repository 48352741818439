import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MBCErrorHandlerButton, MBCErrorMessageComponent } from 'mbc-modals';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageService {

  constructor(
    private modalController: ModalController,
    private translate: TranslateService) { }

  private async errorMessageModal(properties: ErrorMessageProps){
    let modal = await this.modalController.create({
      component: MBCErrorMessageComponent,
      componentProps:{
        header: properties.header,
        message: properties.message,
        button: properties.button,
      },
      cssClass: 'mbc-error-message-modal',
    });
    await modal.present();
  }

  async displayValidationErrorMessage(message: string){
    await this.errorMessageModal({
      header: this.translate.instant("errorMessages.validation.header"),
      message: message,
      button: {
        text: this.translate.instant("errorMessages.validation.okButton")
      }
    })
  }

  async displayErrorMessage(header: string, message: string){
    await this.errorMessageModal({
      header: header,
      message: message,
      button: {
        text: this.translate.instant("errorMessages.okButton")
      }
    })
  }

  async displayGlobalErrorMessage(){
    await this.errorMessageModal({
      header: this.translate.instant("errorMessages.header"),
      message: this.translate.instant("errorMessages.globalError"),
      button: {
        text: this.translate.instant("errorMessages.okButton")
      }
    })
  }

  async displayRefreshErrorMessage(onConfirm: ()=> void){    
    await this.modalController.getTop();
    await this.errorMessageModal({
      header: this.translate.instant("errorMessages.mbClients.authErrorHeader"),
      message: this.translate.instant("errorMessages.mbClients.authErrorMessage"),
      button: {
        text: this.translate.instant("errorMessages.mbClients.authErrorButton"),
        handler: onConfirm
      }
    });
  }
}

export interface ErrorMessageProps{
  header?: string;
  message?: string;
  button?: MBCErrorHandlerButton;
  dismiss?: () => void;
}


