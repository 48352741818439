import { MBPCompanyTrade } from "mbc-models";
import { NodeInfo } from "./node-info.model";

export class MBPDoor {
  id:string;
    doorId:string;
    status:string;
    mbpCurrentTradeInfo: MBPCompanyTradeInfo;
    projectId:string;
    mbeDoorId:string;
    doorNo:string;
    doorty:string;
    building:string;
    comment:string;
    revision:string;
    roomNo:string;
    roomType:MBPRoomType;
    func:string;
    lr:string;
    floor:string;
    fly:string;
    panel:string;

    t1:MBPCompanyTrade;
    t2:MBPCompanyTrade;
    t3:MBPCompanyTrade;
    t4:MBPCompanyTrade;
    t5:MBPCompanyTrade;
    t6:MBPCompanyTrade;
    t7:MBPCompanyTrade;
    t8:MBPCompanyTrade;
    t9:MBPCompanyTrade;
    t10:MBPCompanyTrade;
    t11:MBPCompanyTrade;
    t12:MBPCompanyTrade;
    t13:MBPCompanyTrade;
    t14:MBPCompanyTrade;
    t15:MBPCompanyTrade;

    d2:MBPDoorField;
    d3:string;
    d4:string;
    d5:MBPDoorField;
    d6:MBPDoorField;
    d7:MBPDoorField;
    d8:MBPDoorField;
    d9:MBPDoorField;
    d10:string;
    d11:string;
    d11Qty:number;
    d20:string;
    d21:string;
    d22:string;
    d23:string;
    d24:string;
    d25:string;
    d26:string;
    d27:string;
    d28:string;
    dc1:string;
    dc2:string;
    dc3:string;
    dc4:string;

    hb:string;

    h1:MBPHardware;
    h2:MBPHardware;
    h3:MBPHardware;
    h5:MBPHardware;
    h6:MBPHardware;
    h7:MBPHardware;
    h8:MBPHardware;
    h9:MBPHardware;

    h10:MBPHardware;
    h11:MBPHardware;
    h12:MBPHardware;
    h13:MBPHardware;
    h14:MBPHardware;
    h15:MBPHardware;
    h16:MBPHardware;
    h17:MBPHardware;
    h19:MBPHardware;

    h22:MBPHardware;
    h23:MBPHardware;
    h24:MBPHardware;
    h25:MBPHardware;
    h26:string;
    h27:MBPHardware;
    h28:MBPHardware;
    h29:MBPHardware;

    h30:MBPHardware;
    h31:MBPHardware;
    h32:MBPHardware;
    h33:MBPHardware;
    h35:MBPHardware;

    h40:MBPHardware;
    h41:MBPHardware;
    h42:MBPHardware;
    h43:MBPHardware;
    h44:MBPHardware;
    h45:MBPHardware;
    h46:MBPHardware;
    h47:MBPHardware;
    h48:MBPHardware;
    h49:MBPHardware

    externalId1:string;
    externalId2:string;

    secure:string;
    doorBundle:string;
    changedData?:Date
    changedBy:string; 

    urf1:string;
    urf2:string;
    urf3:string;
    urf4:string;
    urf5:string;
    urf6:string;
    urf7:string;
    urf8:string;

    connected:boolean;
    nodeInfo:NodeInfo;
    doorTags: string[];
}

export class MBPRoomType {
  content?: string;
}

export class MBPDoorField{
  content:string;
  companyId:string;
  mounted:boolean;
}

export class MBPHardware{
  id:string;
  qty:number;
  content:string;
  surf:string;
  companyId:string;
  mounted:boolean;
  checklistId:string;
}

export class MBPCompanyTradeInfo{
  status:string;
  color:string;
  description?:string;
}