export class MBMProject {
    id:string
    mbeProjectId:string;
    isActive:boolean;
    usersInProject:MBMUserInProject[];
}


export class MBMUserInProject{
    id:string;
}