import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable, switchMap } from 'rxjs';
import { BlobUtil } from 'mbc-utils';

@Injectable({
    providedIn: 'root',
})
export class GeneratePDFService {

    constructor(private httpService: HttpService, private blobUtil: BlobUtil) { }

    public fromSvg(svgData: string): Observable<string> {
        return this.httpService.downloadFileByPost(`generatepdf/fromsvg`,{ svgData: svgData })
          .pipe(switchMap(data => this.blobUtil.readBlobAsDataURL(data)));
    }
}
