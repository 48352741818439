import { ChangeType } from "mbc-enums";

export interface DoorNode {
    id: string;
    projectId: string;
    mbeDoorId: string;
    floorId: string,
    xCordinate: number;
    yCordinate: number;
    changedUserId:string;
    changedUserName:string;
    isChanged:boolean; 
    doorNodeEvent:ChangeType;
    nodeColor: string;
    //TODO:Need to refactory for nodetemplate or project node template
    radiusTemplate:number; //Mock Field
    zoomTemplate:number; //Mock Field
    doorTags: string[];
    doorNo: string;
}
