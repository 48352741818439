<ion-app>
  <ion-app>
    <main-dashboard-main-menu></main-dashboard-main-menu>
    <pdf-files-menu></pdf-files-menu>
    <project-dashboard-main-menu></project-dashboard-main-menu>
    <mbe-viewer-main-menu></mbe-viewer-main-menu>
    <mbp-viewer-main-menu></mbp-viewer-main-menu>
    <documents-main-menu></documents-main-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-app>
