import { Signal, WritableSignal, computed, signal } from "@angular/core";
import { MBCColor } from "./mbc-color.model";
import { NodeInfo } from "./node-info.model";

export class MBEDoor {
  id?: string;
  projectId?: string;
  mbeProductionId?: string;
  doorQty?: number;
  doorNo?: string;
  building?: string;
  comment?: string;
  revision?: string;
  roomNo?: string;
  roomType?: MBERoomType;
  func?: string;
  LR?: string;

  D2?: MBEDoorField;
  D3?: string;
  D4?: string;
  D5?: MBEDoorField;
  D6?: MBEDoorField;
  D7?: MBEDoorField;
  D8?: MBEDoorField;
  D9?: MBEDoorField;
  D10?: string;
  D11?: string;
  D11Qty?: number;

  D20?: string;
  D21?: string;
  D22?: string;
  D23?: string;
  D24?: string;
  D25?: string;
  D26?: string;
  D27?: string;
  D28?: string;

  DC1?: string;
  DC2?: string;
  DC3?: string;
  DC4?: string;

  floor?: string;
  fly?: string;
  HB?: string;

  H1?: MBEHardware;
  H10?: MBEHardware;
  H11?: MBEHardware;
  H12?: MBEHardware;
  H13?: MBEHardware;
  H14?: MBEHardware;
  H15?: MBEHardware;
  H16?: MBEHardware;
  H17?: MBEHardware;
  H19?: MBEHardware;

  H2?: MBEHardware;
  H22?: MBEHardware;
  H23?: MBEHardware;
  H24?: MBEHardware;
  H25?: MBEHardware;
  H26?: string;
  H27?: MBEHardware;
  H28?: MBEHardware;
  H29?: MBEHardware;

  H3?: MBEHardware;
  H30?: MBEHardware;
  H31?: MBEHardware;
  H32?: MBEHardware;
  H33?: MBEHardware;
  H35?: MBEHardware;

  H5?: MBEHardware;
  H6?: MBEHardware;
  H7?: MBEHardware;
  H8?: MBEHardware;
  H9?: MBEHardware;

  H40?: MBEHardware;
  H41?: MBEHardware;
  H42?: MBEHardware;
  H43?: MBEHardware;
  H44?: MBEHardware;
  H45?: MBEHardware;
  H46?: MBEHardware;
  H47?: MBEHardware;
  H48?: MBEHardware;
  H49?: MBEHardware;

  HC?: string;

  doorBundle?: string;
  changeBy?: string;

  panel?: string;
  secure?: string;

  URF1?: string;
  URF2?: string;
  URF3?: string;
  URF4?: string;
  URF5?: string;
  URF6?: string;
  URF7?: string;
  URF8?: string;

  revit?: MBERevit;

  doorId?: string;
  status?: string;

  connected: Signal<boolean> = signal(false);
  nodeInfo: WritableSignal<NodeInfo> = signal(undefined);
  color: Signal<MBCColor> = signal(undefined);
  tags: Signal<string[]> = signal([]);

  readOnly: boolean = false;
}

export class MBEDoorField {
  content?: string;
}

export class MBEHardware {
  qty?: number;
  content?: string;
  surf?: string;
}

export class MBERevit {
  revitId?: string;
  format?: string;
  wallThickness?: string;
  fireres?: string;
  soundProof?: string;
  r1?: string;
  r2?: string;
  r3?: string;
  r4?: string;
  r5?: string;
  r6?: string;
  r7?: string;
}

export class MBERoomType {
  content?: string;
}