import { MBMDoorStatus } from "mbc-enums";
import { MBMDoor } from "./mbm-door.model";

export class MBMWorkorderDoor {
    public id: string;
    public projectId: string;
    public workeorderId: string;
    public finishedDate: Date;
    public door: MBMDoor;
    public doorStatus: MBMDoorStatus;
}