import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FloorPlanFile } from 'mbc-models';

@Component({
  selector: 'floor-plan-pdf-card',
  templateUrl: './floor-plan-pdf-card.component.html',
  styleUrls: ['./floor-plan-pdf-card.component.scss'],
  imports: [IonicModule, TranslateModule, CommonModule],
  standalone: true
})
export class FloorPlanPdfCardComponent  implements OnInit {

  @Input() readOnly: boolean;
  @Input() floorPlanFile: FloorPlanFile;
  @Output() replaceFileSelected: EventEmitter<any> = new EventEmitter();
  @Output() removeFileSelected: EventEmitter<any> = new EventEmitter();
  @Output() updateShortName: EventEmitter<any> = new EventEmitter();
  @ViewChild('popover') popover;

  isOpen = false;

  constructor( ) { }

  ngOnInit() {}
  
  presentPopover(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }

  onReplaceFileSelected(){
    this.replaceFileSelected.emit(this.floorPlanFile);
    this.isOpen = false;
  }

  onRemoveFile(){
    this.removeFileSelected.emit(this.floorPlanFile);
    this.isOpen = false;
  }

  onUpdateShortname(){
    this.updateShortName.emit(this.floorPlanFile);
    this.isOpen = false;
  }
}
