import { Injectable } from "@angular/core";
import { StorageService } from "mbc-services";

@Injectable()
export class UserSettingsHandler {

    initialZoom:number;
    defaultZoom:number = 0.25;

    constructor(private storageService: StorageService) { }

    public async getInitialZoom() : Promise<number>{
        let storageZoom = await this.storageService.getInitialZoom();
        this.initialZoom = storageZoom ?? this.defaultZoom;
        return this.initialZoom;
    }

    public async setInitialZoom(initialZoom: number) : Promise<number>{
        this.initialZoom = Number(initialZoom.toFixed(2));
        await this.storageService.setInitialZoom(this.initialZoom);
        return this.initialZoom;
    }

    public async setInitialZoomOut() : Promise<number> {
        return await this.setInitialZoom(this.initialZoom - 0.2);
    }

    public async setInitialZoomIn() : Promise<number> {
        return await this.setInitialZoom(this.initialZoom + 0.2);
    }

    public async resetInitialZoom() : Promise<number>  {
        return await this.setInitialZoom(this.defaultZoom);
    }
}