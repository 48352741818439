import { WritableSignal, signal } from "@angular/core";

export class MBCColorField {
    projectId:string;
    field:string;
    colors: MBCColor[];
}

export class MBCColor{    
    content:string;
    color: string;
    checked?: WritableSignal<boolean> = signal(false);
    disabled?: WritableSignal<boolean> = signal(false);
}