import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError as observableThrowError, Observable, catchError } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MBCError } from 'mbc-models';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private baseUrl!: string;

  constructor(private http: HttpClient) {
    if (environment) {
      this._setEndpoint();
    }
  }

  private _setEndpoint() {
    this.baseUrl = environment.base_url;
  }

  private handleError(error: HttpErrorResponse) {
    let mbcError = new MBCError();
    mbcError.statusCode = error.status;
    mbcError.validationErrorCode = error.error?.validationErrorCode;
    mbcError.validationMessage = error.error?.validationMessage;
    mbcError.originalError = error;
    return observableThrowError(() => mbcError);
  }

  public getObjects<T>(path: string): Observable<T[]> {
    return this.http
      .get<T[]>(this.baseUrl + path)
      .pipe(catchError((error) => this.handleError(error)));
  }

  public getObject<T>(path: string) {
    return this.http
      .get<T>(this.baseUrl + path)
      .pipe(catchError((error) => this.handleError(error)));
  }

  public getObjectByPost<T>(path: string, object: any) {
    return this.http
      .post<T>(this.baseUrl + path, object)
      .pipe(catchError((error) => this.handleError(error)));
  }

  public sendObjects<T>(path: string, object: T) {
    return this.http
      .post<any>(this.baseUrl + path, object)
      .pipe(catchError((error) => this.handleError(error)));
  }
  
  public patchObjects<T>(path: string, object: T) {
    return this.http
      .patch<T>(this.baseUrl + path, object)
      .pipe(catchError((error) => this.handleError(error)));
  }

  public patchAndGetObjects<T,U>(path: string, object: U) {
    return this.http
      .patch<T>(this.baseUrl + path, object)
      .pipe(catchError((error) => this.handleError(error)));
  }

  public updateObjects<T>(path: string, object: T) {
    return this.http
      .put<T>(this.baseUrl + path, object)
      .pipe(catchError((error) => this.handleError(error)));
  }

  public deleteObject<T>(path: string) {
    return this.http
      .delete<any>(this.baseUrl + path)
      .pipe(catchError((error) => this.handleError(error)));
  }

  public uploadFile<T>(url: string, formData: FormData) {
    return this.http
      .post<T>(this.baseUrl + url, formData, {reportProgress: true, observe: 'events'})
      .pipe(catchError((error) => this.handleError(error)))
  }

  public replaceFile<T>(url: string, formData: FormData) {
    return this.http
      .patch<T>(this.baseUrl + url, formData, {reportProgress: true, observe: 'events'})
      .pipe(catchError((error) => this.handleError(error)));
  }

  public downloadFile(url: string){
    return this.http
      .get(this.baseUrl + url, {responseType: 'blob'})
      .pipe(catchError((error) => this.handleError(error)));
  }

  public downloadFileByPost<T>(url: string, object: T){
    return this.http
      .post(this.baseUrl + url, object, {responseType: 'blob'})
      .pipe(catchError((error) => this.handleError(error)));
  }

  public deleteObjects<T>(path: string, object: T) {
    return this.http.delete<any>(this.baseUrl + path, {body: object}).pipe(catchError((error) => this.handleError(error)));
  }
}
