import { ChangeType } from "mbc-enums";
import { NodeInfo } from "mbc-models";

export class DoorNodeChange {
    mbeDoorId: string;
    nodeInfo: NodeInfo;
    changeType: ChangeType;

    constructor(nodeInfo: NodeInfo, mbeDoorId: string, changeType: ChangeType){
        this.nodeInfo = nodeInfo;
        this.mbeDoorId = mbeDoorId;
        this.changeType = changeType;
    }
}