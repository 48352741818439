<div class="mbc-project-card-wrapper" [class.mbc-card-selected]="selected">
  <div class="mbc-project-card-left-box"></div>
  <div class="mbc-project-card-inner">
      
    <div class="mbc-project-card-inner-row">
      <div class="mbc-project-card-name-label">{{mbcProject.name}}</div>
      <div class="mbc-project-card-creadedby-label">
        {{"mbcProjectCard.component.label.createdBy"|translate}}: &nbsp;{{mbcProject.connectedUserName}}
      </div>
    </div>
    <div class="mbc-project-card-inner-row">      
      <img src="/assets/icon/time.png" class="mbc-project-card-time-icon">
      <div class="mbc-project-card-created-date">
        {{mbcProject.createdDate | date:'dd.MM.yyyy hh:mm'}}
      </div>
    </div>
  </div>
  <div class="mbc-project-card-right">
    <img src="/assets/icon/arrow-blue-right.png"
      class="mbc-project-card-navigate-icon"
      alt="{{ 'mbcProjectCard.component.menu.projectDashboard' | translate }}"
      (click)="onNextClick()"/>
    <img src="/assets/icon/dots.png" class="mbc-project-card-dot-icon" (click)="presentPopover($event)">
  </div>
</div>

<ion-popover #popover [isOpen]="isOpen" (didDismiss)="isOpen = false">
  <ng-template>
    <div class="mbc-card-menu">
      <div class="mbc-card-menu-item-important" [class.disabled]="!isAdminUser()" (click)="onDeleteMenuClick()">
        {{"mbcProjectCard.component.menu.deleteProject"|translate}}
      </div>
    </div>
  </ng-template>
</ion-popover>