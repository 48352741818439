import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MBCConfirmationButton, MBCConfirmationComponent } from 'mbc-modals';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {

  constructor(private modalController: ModalController) { }

  async confirm(confirmationProps: ConfirmationProperties){
    let modal = await this.modalController.create({
      component: MBCConfirmationComponent,
      componentProps:{
        header: confirmationProps.header,
        message: confirmationProps.message,
        buttons: confirmationProps.buttons,
      },
      cssClass: ['mbc-confirmation-modal', confirmationProps.cssClass]
    });
    modal.onDidDismiss().then(data => {
      if(!data?.data?.isButtonClick && confirmationProps.dismiss){
        confirmationProps.dismiss();
      }
    })
    await modal.present();
  }

  async confirm_async(confirmationProps: ConfirmationProperties){
    let modal = await this.modalController.create({
      component: MBCConfirmationComponent,
      componentProps:{
        header: confirmationProps.header,
        message: confirmationProps.message,
        buttons: confirmationProps.buttons,
      },
      cssClass: ['mbc-confirmation-modal', confirmationProps.cssClass]
    });
    
    await modal.present();
    let data = await modal.onDidDismiss();
    if(!data?.data?.isButtonClick && confirmationProps.dismiss){
      confirmationProps.dismiss();
    }
  }
}

export interface ConfirmationProperties{
  header?: string;
  message?: string;
  buttons?: MBCConfirmationButton[];
  dismiss?: () => void;
  cssClass?: string;
}
