import { CanActivateFn, Router } from "@angular/router";
import { inject } from "@angular/core";
import { Observable, catchError, map } from "rxjs";
import { AuthGuard } from "mbc-guards";
import { AuthenticationService, ProjectAccessService } from "mbc-services";
import { ErrorHandlingService } from "mbc-errorhandling";

export const isProjectUser: CanActivateFn = (route, state): Observable<boolean> | boolean => {

  let router = inject(Router);
  let navigateUrl = `/main-dashboard`;
  let errorHandler = inject(ErrorHandlingService);

  try{
    let authService = inject(AuthenticationService);
    let accessService = inject(ProjectAccessService);
    let isSignedIn = new AuthGuard(authService).canActivate();

    if(isSignedIn){      
      let projectId = route.paramMap.get("projectId");  
      let previousAccess = true;
      let hasProjectAccess = new Observable<boolean>(subscriber => {
        accessService.userHasProjectAccess(projectId).subscribe({
          next: hasAccess => {
            if(previousAccess && !hasAccess){
              accessService.showNoPermissionMessage();
              router.navigate([navigateUrl]);
            }
            subscriber.next(hasAccess);
            previousAccess = hasAccess;
          },
          error: err => {
            router.navigate([navigateUrl]);
            errorHandler.handleAccessError(err);
            subscriber.next(false);
          },
          complete: () => { subscriber.complete(); }
        });
      });

      return hasProjectAccess;
    }

  }catch(ex){   
    router.navigate([navigateUrl]);
    errorHandler.handleAccessError(ex);
  }
  return false;
}

