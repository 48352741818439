export class TimeoutUtil{
    public waitFor(milliseconds: number): Promise<void> {
        return new Promise<void>(resolve => setTimeout(resolve, milliseconds));
    }

    public async waitAndExecute(execute: ()=> any, ms: number, maxWait: number): Promise<any> {
        let output = execute();
        if(!output){     
            let rounds = maxWait/ms;       
            for(let i = 0; i < rounds; i++){        
                await this.waitFor(ms);
                output = execute();
                if(output){
                    return output;
                }
            }
        }
        return output;
    }
}