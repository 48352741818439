<ion-menu contentId="main-content" side="end" menuId="documents-main-menu">
  <ion-content class="ion-padding">
    <div class="mbc-main-menu-header">{{"mainMenu.header"|translate}}</div>
    <div class="mbc-main-menu-seperator"></div>
    <div class="mbc-main-menu-items-wrapper">
      <div class="mbc-main-menu-item" (click)="goToMainDashboard()" >{{"mainMenu.mainDashboard"|translate}}</div>
    </div>
    <div class="mbc-main-menu-items-wrapper">
      <div class="mbc-main-menu-item" (click)="goToProjectDashboard()">{{"mainMenu.projectDashboard"|translate}}</div>
    </div>
    <div class="mbc-main-menu-items-wrapper">
      <div class="mbc-main-menu-item" (click)="goToMBEViewerPage()">{{"mainMenu.mbeViewer"|translate}}</div>
    </div>
    <!-- <div class="mbc-main-menu-items-wrapper">
      <div class="mbc-main-menu-item" (click)="goToMBPViewerPage()">{{"mainMenu.mbpViewer"|translate}}</div>
    </div> -->
    <div class="mbc-main-menu-items-wrapper">
      <div class="mbc-main-menu-item" (click)="goToFloorPlansPage()">{{"mainMenu.floorPlans"|translate}}</div>
    </div>
    <div class="mbc-main-menu-items-wrapper">
      <div class="mbc-main-menu-item" (click)="logout()">{{"mainMenu.logout"|translate}}</div>
    </div>
  </ion-content>
</ion-menu>