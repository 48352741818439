import { MBEDoor, MBPDoor, TagProperty } from "mbc-models";

export class FieldNameUtil {
  extractValue_MBEDoor(door: MBEDoor, fieldName: string) {
    return fieldName.split('.').reduce((o, key) => {
      const field = key.charAt(0).toLowerCase() + key.slice(1);
      if(o){ return o[field];}
      else return null;
    }, door);
  }
  
  extractValue_MBPDoor(door: MBPDoor, fieldName: string): string {
    return fieldName.split('.').reduce((o, key) => {
      const field = key.charAt(0).toLowerCase() + key.slice(1);
      if(o){ return o[field];}
      else return null;
    }, door);
  }

  extractObjectPropertyValueForViewer(door: MBPDoor, fieldName: string): string {
    return fieldName.split('.').reduce((o, key) => {
      const field = key.charAt(0).toLowerCase() + key.slice(1);
      if(key.toLowerCase().includes('qty')){
        if(o?.[field] && o?.[field] <= 1){
          return null;
        }
      }
      return o?.[field];
    }, door);
  }

  getOrderNumberForField(fieldName: string, tagProperties: TagProperty[]): number {
    return (
      tagProperties.find(
        (tag) => tag.field.toLowerCase() === fieldName.toLowerCase()
      )?.orderNo || 0
    );
  }

  extractFieldName(fieldName: string): string[]{
    return fieldName.split('.').map(c => (c.charAt(0).toLowerCase() + c.slice(1)))
  }

  getFieldNameInClientFormat(fieldName: string): string {
    let namesArray = this.extractFieldName(fieldName);
    return namesArray.join('.');
  }

  setValueForField(object: object, fieldName: string, value: any){
    let namesArray = fieldName.split('.');
    let obj = object;    
    for(let i = 0; i < namesArray.length - 1; i++){
      if(!obj[namesArray[i]]){
        obj[namesArray[i]] = {};
      }
      obj = obj[namesArray[i]];      
      if(i === namesArray.length - 2){
        obj[namesArray[i+1]] = value;
      }
    }
  }
}
