export class MBPDoorMessage {
    id: string;
    inboxId: string;
    subject: string;
    body: string;
    picture: string;
    projectId: string;
    doorNo: string;
    doorId: string;
    sender: MessageUser;
}

export class MessageUser {
    companyId: string;
    mbeUserId: string;
    timestamp: string;
    mbeUserName: string;
}