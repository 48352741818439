import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { MBCColorField, MBPColorField } from 'mbc-models';

@Injectable({
  providedIn: 'root'
})
export class MbcColorService {

  constructor(
    private httpService: HttpService) { }

  /** MBE Color **/
  public getMBCColorsFromMBE_ByProjectField(mbeProjectId: string, fieldName: string): Observable<MBCColorField> {
    return this.httpService.getObject<MBCColorField>(`mbeProjects/${mbeProjectId}/mbcColors/${fieldName}`);
  }

  public updateMBCColorsToMBE_ByProjectId(projectId: string, changedColors: MBCColorField): Observable<MBCColorField> {
    return this.httpService.patchObjects<MBCColorField>(`mbeProjects/${projectId}/mbcColors`, changedColors);
  }

  public getExistingColorsByProjectId(mbeProjectId: string): Observable<MBCColorField> {
    return this.httpService.getObject<MBCColorField>(`mbeProjects/${mbeProjectId}/mbcColors`);
  }

   /** MBP Color **/
   public getMBPColorsById(mbpProjectId: string, companyId: string): Observable<MBPColorField> {
    return this.httpService.getObject<MBPColorField>(`mbpProjects/${mbpProjectId}/companies/${companyId}/mbcColors`);
  }

  public updateMBPColorsById(mbpProjectId: string, companyId: string, changedColors: MBPColorField): Observable<MBPColorField> {
    return this.httpService.patchObjects<MBPColorField>(`mbpProjects/${mbpProjectId}/companies/${companyId}/mbcColors`, changedColors);
  }
}
