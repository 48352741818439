import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MBPAdminUser, MBPCompany, MBPDoor, MBPDoorMessage, MBPDoorStatus, MBPProjectChecklist } from 'mbc-models';

@Injectable({
  providedIn: 'root'
})
export class MbpProjectService {

  constructor(private httpService: HttpService) { }

  public getCompanyUsersbyMBPProjectId(mbpProjectId:string):Observable<MBPCompany[]>{
    return this.httpService.getObject<MBPCompany[]>(`mbpProjects/${mbpProjectId}/companies`);
  }

  public hasMBEDoorIdsSync(mbpProjectId: string): Observable<boolean> {
    return this.httpService.getObject<any>(`mbpProjects/${mbpProjectId}/isMbeDoorsSynced`)
      .pipe(map((data: any) => {
          return data.doorsSynced;
        })
      );
  }

  public getDoorsByTradeCompany(mbpProjectId:string,mbpCompanyId:string):Observable<MBPDoor[]>{
    return this.httpService.getObject<MBPDoor[]>(`mbpProjects/${mbpProjectId}/company/${mbpCompanyId}/doors`).pipe(
      map(doors => doors.sort((a, b) => a.doorNo.localeCompare(b.doorNo)))
    );
  }

  public getCompanyById(mbpProjectId:string,mbpCompanyId:string):Observable<MBPCompany>{
    return this.httpService.getObject<MBPCompany>(`mbpProjects/${mbpProjectId}/companies/${mbpCompanyId}`);
  }

  public getAdminUsersById(mbpProjectId:string):Observable<MBPAdminUser[]>{
    return this.httpService.getObject<MBPAdminUser[]>(`mbpProjects/${mbpProjectId}/adminUsers`);
  }

  public updateDoorStatus(mbpProjectId:string, mbpDoorStatus: MBPDoorStatus): Observable<MBPDoorStatus>{
    return this.httpService.patchObjects<MBPDoorStatus>(`mbpProjects/${mbpProjectId}/doorStatus`, mbpDoorStatus);
  }

  public updateHardwareMount(mbpProjectId:string, mbpCompanyId: string, mountUpdate: HardwareMountUpdate): Observable<boolean>{
    let body = {companyId: mbpCompanyId, fieldName: mountUpdate.fieldName.toUpperCase(), doorId: mountUpdate.doorId, isMounted: mountUpdate.mounted}
    return this.httpService.patchObjects<any>(`mbpProjects/${mbpProjectId}/hardwareMount`, body)
      .pipe(map((data: any) => {
        return data.updated;
      }));
  }

  public getMBPProjectChecklists(mbpProjectId:string):Observable<MBPProjectChecklist[]>{
    return this.httpService.getObject<MBPProjectChecklist[]>(`mbpProjects/${mbpProjectId}/checklists`);
  }

  public sendIssueMessage(mbpProjectId: string, doorMessage: MBPDoorMessage): Observable<MBPDoorMessage> {
    return this.httpService.sendObjects<MBPDoorMessage>(`mbpProjects/${mbpProjectId}/issueMessages`, doorMessage)
      .pipe(map((data: any) => {
        return data.sentMessage;
      }));
  }

  public getMBPIssueMessages(mbpProjectId: string, mbpCompanyId: string): Observable<MBPDoorMessage[]> {
    return this.httpService.getObject<MBPDoorMessage[]>(`mbpProjects/${mbpProjectId}/issueMessages/byCompanyId/${mbpCompanyId}`).pipe(
      map(messages => messages.sort((a,b) => b.sender.timestamp.localeCompare(a.sender.timestamp)))
    );
  }
}

export class HardwareMountUpdate{
  doorId: string;
  fieldName: string;
  mounted: boolean;
}
