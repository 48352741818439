import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmFileUploadCardComponent } from 'mbc-components';
import { FloorPlanFileUploading } from 'mbc-models';

@Component({
  selector: 'confirm-file-upload',
  templateUrl: './confirm-file-upload.component.html',
  styleUrls: ['./confirm-file-upload.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule,TranslateModule, ConfirmFileUploadCardComponent],
})
export class ConfirmFileUploadComponent  implements OnInit {

  @Input() floorPlanFileUploading: FloorPlanFileUploading;

  constructor(private modelCtrl: ModalController) { }

  ngOnInit() {}

  onSubmit(){
    this.modelCtrl.dismiss(this.floorPlanFileUploading);
  }
  
  onCancel() {
    this.modelCtrl.dismiss();
  }
}
