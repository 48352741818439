export class MBPProject {
    id:string
    mbeProjectId:string;
    name: string;
    description:string;
    isInactive:boolean;
    usersInProject:MBPUserInProject[];
}


export class MBPUserInProject{
    id:string;
}