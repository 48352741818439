import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'tab-menu-bar',
  templateUrl: './tab-menu-bar.component.html',
  styleUrls: ['./tab-menu-bar.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class TabMenuBarComponent  implements OnInit {

  @Input() menus: TabMenu[] = [];

  constructor() { }

  ngOnInit() {
  }

  onSelectMenuItem(menu: TabMenu){
    this.menus.find(c => c.selected).selected = false;
    menu.selected = true;
    menu.select();
  }
}

export class TabMenu{
  id?: string;
  title: string;
  selected: boolean;
  select: ()=>void;
}
