export class MBPColorField {
    projectId:string;
    companyId:string;
    colors: MBPColor[];
}

export class MBPColor{    
    shortName:string;
    description:string;
    color:string;
    checked:boolean;
    disabled: boolean;
}