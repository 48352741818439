import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable, map } from 'rxjs';
import { MBEProjectSetting, TagProperty } from 'mbc-models';

@Injectable({
  providedIn: 'root'
})
export class MbeProjectSettingService {

  constructor(
    private httpService: HttpService) {}

  public getMBEProjectSetting(projectId:string) : Observable<MBEProjectSetting>{
    return this.httpService.getObject<MBEProjectSetting>(`project/${projectId}/mbeSettings/`);
  }

  public getMBEProjectTagSetting(projectId:string) : Observable<TagProperty[]>{
    return this.httpService.getObject<TagProperty[]>(`project/${projectId}/mbeSettings/tags`);
  }

  public updateMBEProjectTagSetting(projectId:string,mbeProjectTags: TagProperty[]) : Observable<TagProperty[]>{
    return this.httpService.patchObjects<TagProperty[]>(`project/${projectId}/mbeSettings/tags`,mbeProjectTags);
  }

  public updateDoorNodeTemplate(projectId:string, doorNodeTemplate: number) : Observable<void>{
    let body = {doorNodeTemplate:doorNodeTemplate};
    return this.httpService.patchObjects<any>(`project/${projectId}/mbeSettings/doorNodeTemplate`,body);
  }

  public getEnableAnonymousUser(projectId:string) : Observable<boolean>{
    return this.httpService.getObject<boolean>(`project/${projectId}/mbeSettings/enableAnonymousUser`)
      .pipe(map((data: any) => {
        return data.enableAnonymousUser;
      })
    );
  }

  public updateEnableAnonymousUser(projectId:string, enableAnonymousUser: boolean) : Observable<void>{
    let body = {enableAnonymousUser:enableAnonymousUser}
    return this.httpService.patchObjects<any>(`project/${projectId}/mbeSettings/enableAnonymousUser`,body);
  }
}
