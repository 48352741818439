import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'mbc-services';
import { environment } from 'src/environments/environment';

@Injectable()
export class DocumentsValidationService {

    private notifications = inject(NotificationService);

    constructor(private translate: TranslateService) { }

  validateFiles(documents: File[]) : boolean{
    for (let document of documents) {
      if (!this.checkDocumentType(document.type)) {
        this.notifications.showErrorNotification(
          this.translate.instant(
            "documentValidation.service.message.checkDocumentType", { document: document.name })
        );
        return false;
      }
      if (!this.checkMaxDocumentSize(document.size)) {
        this.notifications.showErrorNotification(
          this.translate.instant(
            "documentValidation.service.message.checkMaxDocumentSize", 
            { document: document.name, size: this.getMaxDocumentSize() }
          )
        );
        return false;
      }
    }
    return true;
  }

  private getMaxDocumentSize(): string {
    const filemaxSize = environment.documentMaxSize;
    const abyte = 1024;
    const size = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(filemaxSize) / Math.log(abyte));
    return (filemaxSize / Math.pow(abyte, i)).toFixed(3) + ' ' + size[i];
  }

  private checkDocumentType(documentType: string): Boolean {
    return (documentType.match(environment.documentTypeAvailable) == null) ? false : true;
  }

  private checkMaxDocumentSize(documentSize: number): Boolean {
    return (documentSize > environment.documentMaxSize) ? false : true;
  }
}