import { CommonModule } from '@angular/common';
import { Component, EventEmitter, input, InputSignal, OnInit, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Document } from 'mbc-models';

@Component({
  selector: 'door-document-card',
  templateUrl: './door-document-card.component.html',
  styleUrls: ['./door-document-card.component.scss'],
  standalone: true,
  imports: [IonicModule, TranslateModule, CommonModule]
})
export class DoorDocumentCardComponent  implements OnInit {

  document: InputSignal<Document> = input(undefined);
  readonly: InputSignal<Boolean> = input(true);
  @Output() downloadClick: EventEmitter<void> = new EventEmitter();
  @Output() detachClick: EventEmitter<void> = new EventEmitter();
  
  constructor() { }

  ngOnInit() {}

  onDownloadClick(){
    this.downloadClick.emit();
  }

  onDetachClick(){
    this.detachClick.emit();
  }
}
