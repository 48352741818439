import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { MBEDoorColorField, MBEProject } from 'mbc-models';

@Injectable({
  providedIn: 'root'
})
export class MbeProjectsService {

  constructor(private httpService: HttpService) { }

  public getDoorColorFieldsByProjectId(mbeProjectId: string): Observable<MBEDoorColorField[]> {
    return this.httpService.getObject<MBEDoorColorField[]>(`mbeProjects/${mbeProjectId}/colorFields`);
  }

  public getById(mbeProjectId: string): Observable<MBEProject> {
    return this.httpService.getObject<MBEProject>(`mbeProjects/${mbeProjectId}`);
  }
}
