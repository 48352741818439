import { ErrorHandler, Injectable, Injector} from "@angular/core";
import { ErrorMessageService } from "mbc-services";

@Injectable()
export class MBCGlobalErrorHandler implements ErrorHandler {

    constructor(private injector: Injector){
    }

    handleError(error: any) {
        let errorMessageService = this.injector.get(ErrorMessageService);
        
        if(!error.handled && (error.originalError && !error.originalError.handled)){
            errorMessageService.displayGlobalErrorMessage();
        }else{
            console.log("error handled");
        }     
    }
  }