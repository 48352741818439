import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'mbc-confirmation',
  templateUrl: './mbc-confirmation.component.html',
  styleUrls: ['./mbc-confirmation.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule,TranslateModule],
})
export class MBCConfirmationComponent  implements OnInit {

  header: string;
  message: string;
  buttons: MBCConfirmationButton[];

  constructor(private modalController: ModalController) { }

  ngOnInit() { }

  onDismiss() {
    this.modalController.dismiss({isButtonClick: false});
  }

  onClick(button: any){    
    if(button.handler) button.handler() ; 
    this.modalController.dismiss({isButtonClick: true});
  }
}

export class MBCConfirmationButton{
  text?: string;
  handler?: () => void ;
}
