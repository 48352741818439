export class MBPTradeTemplate{
    id:string;
    propertyName:string;
    name:string;
    statusMessages:MBPStatusMessage[];
}

export class MBPStatusMessage{
    shortName:string;
    description:string;
}