<div class="floor-menu-bar-container" #container >
  <ion-icon name="arrow-back-circle-outline" class="floor-menu-bar-menu-button floor-menu-bar-menu-button-back" 
    (click)="onScrollToLeft()" *ngIf="showLeftArrow"></ion-icon>
<div class="floor-menu-bar-item-container" #menuItemContainer (scroll)="onscroll()" *ngIf="floorPlanFiles.length > 0">
  <div *ngFor="let file of floorPlanFiles; index as i" class="floor-menu-bar-menu-item" [attr.id]="file.id"
    (click)="onSelectMenuItem(file)" >        
    <div [ngClass]="selectedFloor?.id == file.id? 'floor-menu-bar-menu-selected' : 'floor-menu-bar-menu-unslected'" >
      {{file.shortName}}
    </div>
  </div>
</div>
<ion-icon name="arrow-forward-circle-outline" class="floor-menu-bar-menu-button floor-menu-bar-menu-button-next" 
  (click)="onScrollToRight()" *ngIf="showRightArrow"></ion-icon>
</div>