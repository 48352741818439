import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploading } from 'mbc-models';

@Component({
  selector: 'confirm-file-upload-card',
  templateUrl: './confirm-file-upload-card.component.html',
  styleUrls: ['./confirm-file-upload-card.component.scss'],
  standalone: true,
  imports:[IonicModule, CommonModule, TranslateModule]
})
export class ConfirmFileUploadCardComponent  implements OnInit {
  @Input() confirmFileUploading: FileUploading;
  
  constructor() { }

  ngOnInit() {}
}
