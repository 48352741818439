import { Injectable } from '@angular/core';
import { DoorNode, DoorNodeChange } from 'mbc-models';
import { HttpService } from 'mbc-services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NodeService {

  constructor(
    private httpService: HttpService) {
  }

  public saveNodes(projectId: string, floorId: string, nodes:DoorNode[]): Observable<DoorNode[]> {
    return this.httpService.sendObjects<DoorNode[]>(`projects/${projectId}/floor/${floorId}/doorNodes`,nodes);
  }

  public updateDoorNodeChanges(projectId: string, floorId: string, doorNodeChanges: DoorNodeChange[]): Observable<DoorNode[]>{
    return this.httpService
      .patchAndGetObjects<DoorNode[], DoorNodeChange[]>(`projects/${projectId}/floor/${floorId}/doorNodes`, doorNodeChanges);
  }

  //this method is obsolete
  public getDoorNodesByFloorId(projectId: string): Observable<DoorNode[]>{
    return this.httpService.getObject<DoorNode[]>(`projects/${projectId}/doorNodes`);
  }

  public getDoorNodes(projectId: string): Observable<DoorNode[]>{
    return this.httpService.getObject<DoorNode[]>(`projects/${projectId}/doorNodes`);
  }

  public getDoorNodesCountByProjectId(projectId: string): Observable<number>{
    return this.httpService.getObject<number>(`projects/${projectId}/doorNodes/count`);
  }
}
