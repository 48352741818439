import { Injectable } from '@angular/core';
import { toastController } from '@ionic/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  async showSuccessNotification(message: string){
    let toast = await toastController.create({
      message: message,
      duration: 1500,
      position: "bottom",
      cssClass: "mbc-notification-success"
    });

    await toast.present();
  }

  async showWarningNotification(message: string){
    let toast = await toastController.create({
      message: message,
      duration: 1500,
      position: "bottom",
      cssClass: "mbc-notification-warning"
    });

    await toast.present();
  }

  async showErrorNotification(message: string){
    let toast = await toastController.create({
      message: message,
      duration: 1500,
      position: "bottom",
      cssClass: "mbc-notification-error"
    });

    await toast.present();
  }
}
