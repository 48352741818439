import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { MBMProject } from 'mbc-models';

@Injectable({
  providedIn: 'root'
})
export class MBMProjectService {

  constructor(private httpService: HttpService) { }

  public getProjectByEngineeringId(mbeProjectId:string): Observable<MBMProject>{
    return this.httpService.getObject<MBMProject>(`mbmProjects/mbe/${mbeProjectId}`);
  }
}
