import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IonicModule} from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'empty-mbc-project-card',
  templateUrl: './empty-mbc-project-card.component.html',
  styleUrls: ['./empty-mbc-project-card.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule,TranslateModule]
})
export class EmptyMbcProjectCardComponent  implements OnInit {
  
  @Output() connectProjectClick: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  async onConnectProject() {
    this.connectProjectClick.emit();
  }
}
