import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { DoorHeader } from 'mbc-models';

@Injectable({
  providedIn: 'root'
})
export class ProjectSettingsService {

  constructor(
    private httpService: HttpService) { }

  public updateDoorNodeSizeZoom(projectId:string, doorNodeSizeZoom: number) : Observable<void>{
    let body = { doorNodeSizeZoom: doorNodeSizeZoom };
    return this.httpService.patchObjects<any>(`projects/${projectId}/settings/doorNodeSizeZoom`,body);
  }

  public getDoorGridHeaders(projectId:string) : Observable<DoorHeader[]>{
    return this.httpService.getObject<DoorHeader[]>(`projects/${projectId}/settings/documentsPage/doorGridHeaders`);
  }

  public updateDoorGridHeaders(projectId:string, doorHeaders: DoorHeader[]) : Observable<DoorHeader[]>{
    return this.httpService.patchObjects<DoorHeader[]>(`projects/${projectId}/settings/documentsPage/doorGridHeaders`,doorHeaders);
  }
}
