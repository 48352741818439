import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DoorNodeFilterOptionModel } from 'mbc-components';

@Component({
  selector: 'door-node-filter-option-card',
  templateUrl: './door-node-filter-option-card.component.html',
  styleUrls: ['./door-node-filter-option-card.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule,TranslateModule],
})
export class DoorNodeFilterOptionCardComponent  implements OnInit {

  @Input() displayItem: DoorNodeFilterOptionModel;

  @Output() optionChecked = new EventEmitter<DoorNodeFilterOptionModel>();

  constructor() { }

  ngOnInit() {}

  optionSelected(){
    this.optionChecked.emit(this.displayItem);
  }

}
