import { Injectable } from '@angular/core';
import { User, UserManager } from 'oidc-client-ts';
import { environment } from 'src/environments/environment';
import { DatacontextService } from './datacontext.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  user!: User;
  userManager: UserManager;

  constructor(
    public dataContextService: DatacontextService,
    private window: Window
    ) {
      let domainUrl = this.window.location.origin;
      const settings = {
        authority: environment.authSettings.authority,
        client_id: environment.authSettings.client_id,
        redirect_uri: domainUrl + '/' + environment.authSettings.authCallbackPath,
        post_logout_redirect_uri: domainUrl + '/',
        response_type: 'code',
        prompt: 'login',
        scope: environment.authSettings.scope,
        client_secret: environment.authSettings.clientSecret,
        automaticSilentRenew: true,
    };
    
    this.userManager = new UserManager(settings);
    this.userManager.events.addSilentRenewError(() => {
      this.logout();
    });
  }

  async getUser(): Promise<User | null> {
    let user = this.userManager.getUser();
    this.getLogginUserDetails(user)
    return user;
  }

  async getLogginUserDetails(user: Promise<User>) {
    this.dataContextService.loggedInUser.id =  (await user).profile?.['id'];
    this.dataContextService.loggedInUser.name =  (await user).profile?.name;
  }

  login(): Promise<void> {
    const state = {
      pathname: location.pathname,
      queryParams: location.search.substring(1),
    };
    return this.userManager.signinRedirect({ state: JSON.stringify(state) });
  }

  async logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }

  async completeAuthentication() {
    this.user = await this.userManager.signinRedirectCallback();
  }

  isAuthenticated(): boolean {
    return this.user != null && !this.user.expired;
  }

  async getState() {
    return this.user?.state;
  }

  /**
   * @ToDo Don't need this method at the moment
   */
  renewToken(): Promise<User | null> {
    return this.userManager.signinSilent();
  }
}
