import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { FileUploading, FloorPlanFile } from 'mbc-models';
import { NotificationService } from 'mbc-services';
@Injectable({
  providedIn: 'root'
})
export class FileValidationService {

  constructor(
    private notificationService: NotificationService,
    private translate: TranslateService) { }

  newFileValidation(file: File, newPdfFiles: FloorPlanFile[], replacingFiles: File[]): boolean {
    var isValid = true;

    if (replacingFiles.length > 0) {
      for(let replacingFile of replacingFiles){
        if (file.name == replacingFile.name) {
          this.notificationService.showErrorNotification(
            this.translate.instant("projectDashboard.page.fileValidation.editReplacingExist",
              { filename: replacingFile.name }));
          isValid = false;
        }
      }
    }
    
    let tmpFile = newPdfFiles.find(c => c.pdfFileName === file.name)
    if (tmpFile != null) {
      this.notificationService.showErrorNotification(
        this.translate.instant("projectDashboard.page.fileValidation.replaceExist",
          { filename: file.name }));
          isValid = false;
    }

    return isValid;
  }

  newReplacingFileValidation(uploadedFiles: File[],replacingFiles: File[]){
    var isValid = true;
    if (replacingFiles.length > 0) {
      for(let replacingFile of replacingFiles)
      {
        for(let uploadedFile of uploadedFiles)
        {
          if (uploadedFile.name == replacingFile.name) {
    
            this.notificationService.showErrorNotification(
              this.translate.instant("projectDashboard.page.fileValidation.editReplacingExist",
                { filename: replacingFile.name }));
      
            isValid = false;
          }
        };
      }
    }

    return isValid;
  }

  getFileDuplicate(files: File[], newPdfFiles: FloorPlanFile[], isGetDuplicate:boolean): FileUploading[] {

    var fileResult:FileUploading[] = [];
    var fileCount = 2;
    for(let file of files)
    {
      let tmpFile = newPdfFiles.find(c => c.pdfFileName === file.name)

      if(isGetDuplicate){
        if (tmpFile != null) {
          
          fileResult.push({
            file: file,
            order: tmpFile.order,
            oldFile: tmpFile
          });
        }
      }
      else{
        if (tmpFile == null) {
          fileResult.push({
            file: file,
            order: newPdfFiles.length + fileCount++
          });
        }
      }
      
    }
    
    return fileResult;
  }

  fileValidation(files: File[], newPdfFiles: FloorPlanFile[]): boolean {
    var isValid: boolean = true;
    for (let file of files) {
      //check file type
      if (!this.validationFileType(file.type)) {
        
        //Should use other way to notification for multiple validation
        this.notificationService.showErrorNotification(
          this.translate.instant("projectDashboard.page.fileValidation.fileType"));

        isValid = false;
      }

      //check file size
      if (!this.validationFileSize(file.size)) {

        //Should use other way to notification for multiple validation
        this.notificationService.showErrorNotification(
          this.translate.instant("projectDashboard.page.fileValidation.fileSize",
            { size: this.getFileMaxSize() }));

        isValid = false;
      }
    }

    return isValid;
  }

  getFileMaxSize(): string {
    const filemaxSize = environment.fileMaxSize;
    const abyte = 1024;
    const size = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(filemaxSize) / Math.log(abyte));
    return (filemaxSize / Math.pow(abyte, i)).toFixed(3) + ' ' + size[i];
  }

  validationFileType(fileType: string): Boolean {
    return (fileType.match(environment.fileTypeAvailable) == null) ? false : true;
  }

  validationFileSize(fileSize: number): Boolean {
    return (fileSize > environment.fileMaxSize) ? false : true;
  }
}
