import { MBEProjectUserRole } from "./mbe-project-user-role.enum";

export class MBEUser{
    id:string;
    name:string;
    roleId:MBEProjectUserRole;
    email:string;
    companyName:string;
    companyId:string;
    departmentName:string;
    departmentId:string;
    mobile:string;
}