import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ErrorHandlingService } from 'mbc-errorhandling';
import { AuthGuard } from 'mbc-guards';
import { AuthenticationService, ProjectAccessService } from 'mbc-services';
import { Observable, catchError, map } from 'rxjs';

export const isProjectMBPUserOrMBEUser: CanActivateFn = (route, state): Observable<boolean> | boolean => {

  let router = inject(Router);
  let navigateUrl = `/projects/`;
  let errorHandler = inject(ErrorHandlingService);
  try {
    let authService = inject(AuthenticationService);
    let accessService = inject(ProjectAccessService);
    let isSignedIn = new AuthGuard(authService).canActivate();
    if (isSignedIn) {
      
      let projectId = route.paramMap.get("projectId");
      navigateUrl += projectId;let previousAccess = true;

      let hasProjectAccess = new Observable<boolean>(subscriber => {
        accessService.userHasMBPViewerAccess(projectId).subscribe({
          next: hasAccess => {
            if(previousAccess && !hasAccess){
              accessService.showNoPermissionMessage();
              router.navigate([navigateUrl]);
            }
            subscriber.next(hasAccess);
            previousAccess = hasAccess;
          },
          error: err => {
            router.navigate([navigateUrl]);
            errorHandler.handleAccessError(err);
            subscriber.next(false);
          },
          complete: () => { subscriber.complete(); }
        });
      });
      return hasProjectAccess;
    }

  } catch (ex) {
    router.navigate([navigateUrl]);
    errorHandler.handleAccessError(ex);
  }
  return false;
}

