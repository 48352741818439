import { signal, Signal, WritableSignal } from "@angular/core";
import { MBMDoorStatus } from "mbc-enums";
import { NodeInfo } from "mbc-models";

export class MBMDoor
{
    id: string;
    doorId: string;
    status: string;
    projectId: string;
    mbeProductionId: number;
    doorQty?: number;
    doorNo: string;
    building: string;
    comment: string;
    revision: string;
    roomNo: string;
    roomType: MBMRoomType;
    func: string;
    lr: string;
    mbeDoorId: string;

    d2: MBMDoorField;
    d3: string;
    d4: string;
    d5: string;
    d6: string;
    d7: string;
    d8: string;
    d9: string;
    d10: string;
    d11: string;
    d11Qty?: number;
    d20: string;
    d21: string;
    d22: string;
    d23: string;
    d24: string;
    d25: string;
    d26: string;
    d27: string;
    d28: string;
    dc1: string;
    dc2: string;
    dc3: string;
    dc4: string;

    floor: string;
    fly: string;

    hb: string;
    h1: MBMHardware;
    h10: MBMHardware;
    h11: MBMHardware;
    h12: MBMHardware;
    h13: MBMHardware;
    h14: MBMHardware;
    h15: MBMHardware;
    h16: MBMHardware;
    h17: MBMHardware;
    h19: MBMHardware;
    h2: MBMHardware;
    h22: MBMHardware;
    h23: MBMHardware;
    h24: MBMHardware;
    h25: MBMHardware;
    h26: string;
    h27: MBMHardware;
    h28: MBMHardware;
    h29: MBMHardware;
    h3: MBMHardware;
    h30: MBMHardware;
    h31: MBMHardware;
    h32: MBMHardware;
    h33: MBMHardware;
    h35: MBMHardware;
    h5: MBMHardware;
    h6: MBMHardware;
    h7: MBMHardware;
    h8: MBMHardware;
    h9: MBMHardware;
    h40: MBMHardware;
    h41: MBMHardware;
    h43: MBMHardware;
    h44: MBMHardware;
    h45: MBMHardware;
    h46: MBMHardware;
    h47: MBMHardware;
    h48: MBMHardware;
    h49: MBMHardware;

    hc: string;
    doorBundle: string;
    changedBy: string;
    panel: string;
    externalId1: string;
    externalId2: string;
    secure: string;
    urf1: string;
    urf2: string;
    urf3: string;
    urf4: string;
    urf5: string;
    urf6: string;
    urf7: string;
    urf8: string;

    color: Signal<string> = signal('');
    doorStatus: WritableSignal<MBMDoorStatus> = signal(undefined);
    nodeInfo: WritableSignal<NodeInfo> = signal(undefined);
    doorTags: Signal<string[]> = signal(undefined);
}

export class MBMRoomType
{
    content: string;
}

export class MBMHardware
{
    id: string;
    isMaintainable: boolean;
    qty?: number;
    content: string;
    surf: string;
    isMaintained: boolean;
    checklistId: string;
}

export class MBMDoorField
{
    content: string;
    companyId: string;
    mounted: boolean;

}