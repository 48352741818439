import { WritableSignal, signal } from '@angular/core';

export class Document {
    id:string;
    projectId:string;
    fileName:string;
    shortName:string;
    lastUploadedDate: Date;
    changedDate: Date;
    uploadedUserName: string;
    progress: WritableSignal<number> = signal(undefined);
}

export class DoorDocument{
    doorId: string;
    files: DoorFile[];
}

export class DoorFile{
    documentId: string;
    order: number;
}