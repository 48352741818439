<div>
  <p class="empty-mbc-project-card-description">
    {{"emptyMbcProjectCard.component.description"|translate}}
  </p>
</div>

<div>
  <p class="empty-mbc-project-card-label">
    {{"emptyMbcProjectCard.component.label"|translate}}
  </p>
</div>

<div class="empty-mbc-project-card-div">
  <ion-button class="mbc-button-round-primary" (click)="onConnectProject()">
    <ion-icon name="add-outline"></ion-icon>
  </ion-button>

</div>
