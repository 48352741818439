export class FloorPlanFile{

    id: string;
    changedDate: Date;
    pdfFileName: string;
    lastUploadedDate: Date;
    uploadedUserName: string;
    order: number;
    progress: number;
    shortName?: string;
}