import { CommonModule, Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { IonicModule, Platform } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DocumentsMainMenuComponent, MainDashboardMainMenuComponent, MBEViewerMainMenuComponent, MBPViewerMainMenuComponent, 
  PdfFilesMenuComponent, ProjectDashboardMainMenuComponent } from 'mbc-components';
import { DatacontextService } from 'mbc-services';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    RouterLink,
    RouterLinkActive,
    CommonModule,
    TranslateModule,
    ProjectDashboardMainMenuComponent,
    PdfFilesMenuComponent,
    MBEViewerMainMenuComponent,
    MBPViewerMainMenuComponent,
    DocumentsMainMenuComponent,
    MainDashboardMainMenuComponent
  ],
})
export class AppComponent {

  constructor(public translate: TranslateService, public dataContextService: DatacontextService, private router: Router,
    private location: Location, private platform: Platform) {
      translate.addLangs(['en', 'no', 'se']);
      translate.setDefaultLang('no');
      this.translate.use('no');
      dataContextService.logConfigInfo();
      this.initializePlatform();
  }

  initializePlatform(){
    this.platform.ready().then(() => {
      this.validatePlatformSize();
    });
    this.platform.resize.subscribe(() => {
      this.validatePlatformSize();
    });
  }

  validatePlatformSize() {
    if (this.platform.width() < 768) {
      // refactor - Display an overlay or -
      // navigate to a friendly mobile page "We are working on a mobile version of this page. Please visit us later."
      this.router.navigate(['/static-mobile-friendly']);
    }
    else {
      if (this.router.url == '/static-mobile-friendly') {
        this.location.back();
      }
    }
  }
}
