import { Injectable } from '@angular/core';
import { Observable, map, switchMap} from 'rxjs';
import { MBEProjectUserRole } from 'mbc-models';
import { ObservableUtil } from 'mbc-utils';
import { DatacontextService, MbeProjectSettingService, NotificationService, ProjectService } from 'mbc-services';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ProjectAccessService {
  observableUtil = new ObservableUtil();

  constructor(
    private projectService: ProjectService, 
    private mbeProjectSettingService: MbeProjectSettingService,
    private dataContext: DatacontextService,
    private notification: NotificationService,
    private translate: TranslateService) { }

  public userHasProjectAccess(projectId: string): Observable<boolean>{
    return this.loadProjectPermission(projectId).pipe(
      map(() => {
        let hasProjectAccess = this.isUserAllowedInMBEProject() || this.isUserAllowedInMBPProject() || this.isUserAllowedInMBMProject();
        return hasProjectAccess;
      })
    );
  }

  public userHasMBPViewerAccess(projectId: string): Observable<boolean>{
    return this.loadProjectPermission(projectId).pipe(
      switchMap(() => {
        let hasMBPAccess = this.isUserAllowedInMBEProject() || this.isUserAllowedInMBPProject();
        if (hasMBPAccess) {
          return this.observableUtil.getObservable<boolean>(hasMBPAccess);
        }
        return this.mbeProjectSettingService.getEnableAnonymousUser(projectId);
      })
    );
  }

  public userHasMBEProjectAccess(projectId: string): Observable<boolean>{
    return this.loadProjectPermission(projectId).pipe(
      map(() => {
        let hasMBEAccess = this.isUserAllowedInMBEProject();
        return hasMBEAccess;
      })
    );
  }

  private loadProjectPermission(projectId: string): Observable<void>{
    let permissions = new Observable<void>(subscriber => {
      if(this.dataContext.currentProjectContext?.projectId == projectId){
        subscriber.next();
      }   
      this.projectService.getPermissions(projectId).subscribe({
        next: projectContext => {          
          this.dataContext.currentProjectContext = projectContext;
          subscriber.next();
          subscriber.complete();
        },
        error: err => subscriber.error(err)
      });
    });
    return permissions;
  }

  public userHasMBEViewerAccess(projectId: string): Observable<boolean> {
    return this.userHasMBEProjectAccess(projectId).pipe(
      switchMap(hasMBEAccess => {
        if (hasMBEAccess) {
          return this.observableUtil.getObservable<boolean>(hasMBEAccess);
        }

        return this.mbeProjectSettingService.getEnableAnonymousUser(projectId).pipe(
          map(enableAnonymousUser => {
            return enableAnonymousUser;
          })
        );
      })
    );
  }

  public userHasMBEWriteAccess(projectId: string): Observable<boolean>{
    return this.userHasMBEProjectAccess(projectId).pipe(
      map(hasMBEAccess => {
        return hasMBEAccess && this.dataContext.currentProjectContext.mbeUserRights != MBEProjectUserRole.READ_ONLY;
    }));
  }

  public isUserAllowedInMBEProject(){
    return this.dataContext.currentProjectContext.mbeUserRights != MBEProjectUserRole.NO_ACCESS;
  }

  public isUserAllowedInMBPProject(){
    return (this.dataContext.currentProjectContext?.mbpUserRights?.isProjectAdmin
    || this.dataContext.currentProjectContext?.mbpUserRights?.isCompanyUser
    || this.dataContext.currentProjectContext?.mbpUserRights?.isCompanyLeader
    || this.dataContext.currentProjectContext?.mbpUserRights?.isProjectLeader);
  }

  public isUserAllowedInMBMProject(){
    return (this.dataContext.currentProjectContext?.mbmUserRights?.isProjectAdmin
      || this.dataContext.currentProjectContext?.mbmUserRights?.isCompanyUser);
  }

  showNoPermissionMessage(){
    this.notification.showWarningNotification(this.translate.instant("accessGuard.noPermissionMessage"));
  }

  public hasPdfFileAccess(): boolean{
    return (this.dataContext.currentProjectContext?.mbeUserRights == MBEProjectUserRole.READ_ONLY
      || this.dataContext.currentProjectContext?.mbeUserRights == MBEProjectUserRole.NO_ACCESS);
  }
}
