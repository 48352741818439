import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { FloorPlanFile } from 'mbc-models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FloorPlanFileService {
  
  constructor(private httpService: HttpService) {}

  public updateFloorPlanFiles(floorPlanFiles: FloorPlanFile[]): Observable<FloorPlanFile[]> {
    return this.httpService
      .updateObjects<FloorPlanFile[]>('floorPlanFiles', floorPlanFiles);
  }
}
