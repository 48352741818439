<ion-header class="mbc-confirmation-header">
  <ion-toolbar class="mbc-confirmation-toolbar">
    <ion-title class="mbc-confirmation-title">
      {{header}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onDismiss()">
        <ion-icon name="close-outline" class="mbc-confirmation-close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="mbc-confirmation-content">
  <div class="mbc-confirmation-message">
    {{message}}
  </div>
  <div class="mbc-confirmation-button-wrapper">
    <ion-button *ngFor="let button of buttons" class="mbc-button-primary" (click)="onClick(button)">
      {{button.text}}
    </ion-button>
  </div>
</ion-content>