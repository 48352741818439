<ion-header class="mbc-modal-header">
  <ion-toolbar class="mbc-modal-toolbar">
    <ion-title class="mbc-modal-title">{{"confirmFileUpload.modal.title"|translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCancel()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="mbc-modal-content-container">
    <div class="mbc-main-tile">
      @if(floorPlanFileUploading.newFile.length >0) {
        <div class="mbc-main-tile-header">
          <div class="mbc-main-tile-header-name">{{"confirmFileUpload.modal.newFilesTile.headerName"|translate}}</div>
          <div class="mbc-main-tile-header-info">
            {{"confirmFileUpload.modal.newFilesTile.headerInfo"|translate:{count: floorPlanFileUploading.newFile.length} }}
          </div>
        </div>   
        <div class="mbc-main-tile-content" #mainTileContent>   
          @for (file of floorPlanFileUploading.newFile; track $index) {
            <confirm-file-upload-card [confirmFileUploading]="file"></confirm-file-upload-card>
          }
        </div>
      }
      @if(floorPlanFileUploading.replaceFile.length >0) {
        <div class="mbc-main-tile-header">
          <div class="mbc-main-tile-header-name">{{"confirmFileUpload.modal.changedFilesTile.headerName"|translate}}</div>
          <div class="mbc-main-tile-header-info">
            {{"confirmFileUpload.modal.changedFilesTile.headerInfo"|translate:{count: floorPlanFileUploading.replaceFile.length} }}</div>
        </div>   
        <div class="mbc-main-tile-content">     
          @for (file of floorPlanFileUploading.replaceFile; track $index) {
            <confirm-file-upload-card [confirmFileUploading]="file"></confirm-file-upload-card>
          }
        </div>
      }
    </div>
    <div class="mbc-modal-button-wrapper">
      <ion-button class="mbc-button-primary" (click)="onCancel()">
        Avbryt
      </ion-button>
      <ion-button class="mbc-button-primary" (click)="onSubmit()">
        Last opp
      </ion-button>
    </div>
  </div>
</ion-content>