import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingScreenService {

  constructor(private loadingCtrl:LoadingController) { }

  async show(){
    this.loadingCtrl.create({
      spinner: "circles",
      cssClass: "mbc-loading-screen"
    }).then((response) => {
      response.present();
    });
  }

  async dismiss(){
    this.loadingCtrl.dismiss();
  }

}
