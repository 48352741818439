import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { MenuController } from "@ionic/angular";
import { AuthenticationService, DatacontextService } from "mbc-services";

export abstract class MainMenu{
    
    private dataContext = inject(DatacontextService);

    constructor(private authService: AuthenticationService, private menuController: MenuController, private router: Router){}

    public goToMainDashboard(){        
        this.navigateToPage(`/main-dashboard`);
    }

    public goToDocumentPage(){
        this.navigateToPage(`/projects/` + this.dataContext.currentProjectContext?.projectId + `/documents`);
    }
  
    public goToProjectDashboard(){
        this.navigateToPage(`/projects/${this.dataContext.currentProjectContext?.projectId}/dashboard`);
    }

    public goToFloorPlansPage(){
        this.navigateToPage(`/projects/${this.dataContext.currentProjectContext?.projectId}/floor-plan-files`);
    }

    public goToMBEViewerPage(){
        this.navigateToPage(`/projects/${this.dataContext.currentProjectContext?.projectId}/floor-plan-viewer`);
    }

    public goToMBPViewerPage(){
        this.navigateToPage(`/projects/${this.dataContext.currentProjectContext?.projectId}/progress-floor-plan-viewer/:mbpCompanyId`);
    }

    public logout(){
        this.closeMenu();
        this.authService.logout();
    }

    protected closeMenu(){
        this.menuController.close();
    }

    protected navigateToPage(url: string){
        this.router.navigate([url]);
        this.closeMenu();
    }
}